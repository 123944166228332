import { request } from '../../Common/Requests/request';
import { getAppConfig } from '../../Config';
import {
  IStationShiftsRequest,
  IStationShiftsResponse
} from '../Types/stationShifts';

export const getStationShiftsData = async ({
  location,
  stationTypeId
}: IStationShiftsRequest): Promise<IStationShiftsResponse> => {
  if (!location || !stationTypeId) {
    throw new Error('Location is required');
  }
  const isExternalDashboard = getAppConfig().isExternalDashboard;
  const shiftsRequestData: {
    url: string;
    method: "GET" | "POST";
    body?: any;
  } = {
    url: `${
      getAppConfig().shiftApiUrl || ''
    }${isExternalDashboard ?`?location=${location}&stationTypeId=${stationTypeId}`:''}`,
    method: isExternalDashboard ? "GET" : "POST",
    
  };

  if(!isExternalDashboard){
    shiftsRequestData.body= {
      type: "insight_shift",
      stationTypeIds: stationTypeId.split(","),
      location: location
    }
  }


  
  const layoutResponse = await request<IStationShiftsResponse>(shiftsRequestData);

  return layoutResponse;
};
