import { useCallback, useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../Constants/themeContext';
import { getSelectedTheme, setSelectedTheme } from '../Helpers/themeHelpers';
import { Theme } from '../Types/theme';

export const useDashboardTheme = () => {
  const selectedTheme = useContext(ThemeContext);
  const [theme, setTheme] = useState(selectedTheme);
  useEffect(() => {
    setTheme(getSelectedTheme());
  }, [getSelectedTheme(),selectedTheme]);

  const toggleTheme = useCallback(() => {
    setTheme((prev: Theme) => {
      const newTheme = prev === Theme.dark ? Theme.light : Theme.dark;
      setSelectedTheme(newTheme);
      return newTheme;
    });
  }, []);

  return {
    theme,
    toggleTheme
  };
};
