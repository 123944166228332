import { useCallback, useEffect } from 'react';
import { useSocket } from '../../Common/Hooks/useSocket';
import Events from '../../Common/Utils/events';
import Logger from '../../Common/Utils/logger';
import { locationLayoutReducer } from '../Helpers/locationLayoutStateHelper';
import { ILocationLayoutResponse } from '../Types';
import {
  AvgJourneyData,
  DetectionEventData,
  DeviceStatusData,
  SocketUpdate,
  UPDATE_TYPES,
  VehicleCountData
} from '../Types/socket';

const TRIGGER_TYPE = 'Exit';
export function useCompanyLocationSocket({
  updateDataDispatch
}: {
  updateDataDispatch: React.Dispatch<
    React.SetStateAction<ILocationLayoutResponse | null>
  >;
}) {
  const socket = useSocket();

  const updateDataHandler = useCallback(
    (update: SocketUpdate) => {
      updateDataDispatch((prevData) => locationLayoutReducer(prevData, update));
    },
    [updateDataDispatch]
  );

  useEffect(() => {
    if (!socket) {
      return;
    }

    const detectionEventHandler = ({
      data
    }: {
      error?: Error;
      message?: string;
      data: DetectionEventData;
    }) => {
      updateDataHandler({ type: UPDATE_TYPES.DETECTION, data });
      if (data.triggerType === TRIGGER_TYPE) {
        Events.notify(Events.type.FETCH_STATS, data);
      }
    };

    const averageJourneyEventHandler = ({
      data
    }: {
      error?: Error;
      message?: string;
      data: AvgJourneyData;
    }) => {
      updateDataHandler({ type: UPDATE_TYPES.AVG_JOURNEY, data });
    };

    const vehicleCountEventHandler = ({
      data
    }: {
      error?: Error;
      message?: string;
      data: VehicleCountData;
    }) => {
      Logger(false && data);
      // updateDataHandler({ type: UPDATE_TYPES.VEHICLE_COUNT, data });
    };


    const deviceStatusEventHandler = ({
      data
    }: {
      error?: Error;
      message?: string;
      data: DeviceStatusData;
    }) => {
      updateDataHandler({ type: UPDATE_TYPES.DEVICE_STATUS, data });
    };


    socket.on('drivethruHailo:detection', detectionEventHandler);
    socket.on('drivethruHailo:averageJourney', averageJourneyEventHandler);
    socket.on('drivethruHailo:vehicleCount', vehicleCountEventHandler);
    socket.on('alerts:deviceStatusChange', deviceStatusEventHandler);

    return () => {
      socket.off('drivethruHailo:detection', detectionEventHandler);
      socket.off('drivethruHailo:averageJourney', averageJourneyEventHandler);
      socket.off('drivethruHailo:vehicleCount', vehicleCountEventHandler);
      socket.off('alerts:deviceStatusChange', deviceStatusEventHandler);

    };
  }, [socket]);

  useEffect(() => {
    const cancelStationTimerHandler = ({
      stationId
    }: {
      stationId: string;
    }) => {
      updateDataHandler({
        type: UPDATE_TYPES.CANCEL_STATION_TIMER,
        data: { stationId }
      });
    };

    Events.registerEvent(
      Events.type.CANCEL_STATION_TIMER,
      cancelStationTimerHandler
    );

    return () => {
      Events.unregisterEvent(
        Events.type.CANCEL_STATION_TIMER,
        cancelStationTimerHandler
      );
    };
  }, [updateDataHandler]);

  return socket;
}
