import React, { useEffect, useState } from 'react';
import { Shifts } from '../../Types/stationShifts';
import styles from './StationTypeStats.module.scss';
import { getTimeDifferenceString } from './utils';

interface TimeTagProps {
  shifts: Shifts;
  shift: string;
  index: number;
}

function TimeTag({ shifts, shift, index }: TimeTagProps) {
  const [time, setTime] = useState<string | null>(null);
// Logger(time,"time")
  useEffect(() => {    
    const timeInterval = setInterval(() => {
      const calculatedTime = getTimeDifferenceString(shift, shifts, index);
      setTime(calculatedTime || null);
      }, 600);
    // Clear the interval on component unmount
    return () => clearInterval(timeInterval);
  }, [shift, shifts, index]);
  if (time) {
    return <span className={styles.timeTag}>{time}</span>;
  }
  return null;
}

export default TimeTag;
