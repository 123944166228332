import { now } from '../../Common/Utils/dateTime';
import Logger from '../../Common/Utils/logger';
import { AccessPointType, ILocationLayoutResponse, Stations } from '../Types';
import {
  AvgJourneyData,
  CancelStationTimerData,
  DetectionEventData,
  DeviceStatusData,
  SocketUpdate,
  UPDATE_TYPES,
  VehicleCountData
} from '../Types/socket';

export const calculateAtPremiseVehicleCount = (stations: Stations): number => {
  return stations.reduce((count, { inQueueVehicleCount }) => {
    return count + (inQueueVehicleCount || 0);
  }, 0);
};

const updateStations = (
  stations: Stations,
  updates: AvgJourneyData['stations']
): Stations => {
  return stations.map((station) => {
    const { stationId } = station;
    const updatedStationDetails = updates.find(
      ({ stationId: id }) => id === stationId
    );

    if (!updatedStationDetails) {
      return station;
    }

    const { lastHourGoalMet, avgTime } = updatedStationDetails;

    return {
      ...station,
      avgTime,
      lastHourGoalMet
    };
  });
};

export const locationLayoutReducer = (
  state: ILocationLayoutResponse | null,
  updates?: SocketUpdate
): ILocationLayoutResponse | null => {
  if (!state || !updates) {
    // if state if not from api then do not update socket events in the response
    return state;
  }

  const { type, data } = updates;
  Logger(type, data, 'current Time - ', new Date().toISOString());

  switch (type) {
    case UPDATE_TYPES.DETECTION: {
      const {
        stationId: eventStationId,
        triggerType,
        time: eventTime,
        uid:eventVehicleUid
      } = data as DetectionEventData;
      if (!eventTime) {
        return state;
      }

      const time = new Date(eventTime).getTime();
      const diff = (now() - time) / 1000;

      if (diff > 3600) {
        // > 1 HR
        return state;
      }

      const stations = state.data.stations.map((station) => {
        const { stationId,crossCameraStation} = station;
        if (eventStationId !== stationId) {
          return station;
        }
        if (triggerType === AccessPointType.ENTRY) {
          if(crossCameraStation){
            return {
              ...station,
              vehicles: [...station.vehicles, { currentVehicleTime: time, uid: eventVehicleUid }],
            };
          }
          return {
            ...station,
            vehicles:[{ currentVehicleTime: time, uid: eventVehicleUid }]
          }
          
        }
        if (eventStationId === stationId && station.vehicles && station.vehicles.length > 0) {
         
          // remove the first element from the vehicles array for cross camera station
         if(crossCameraStation){
          const [, ...restVehicles] = station.vehicles;
         
      
          return {
            ...station,
            vehicles: restVehicles,
          };
         }

         // remove the matching uid element from the vehicles array for single camera station
         const updatedVehicles=station.vehicles.filter((vehicle)=>vehicle.uid!==eventVehicleUid)
         return {
          ...station,
          vehicles: updatedVehicles,
        };
        }


        return station
      });

      return {
        ...state,
        data: {
          ...state.data,
          stations,
          atPremiseVehicleCount: calculateAtPremiseVehicleCount(stations)
        }
      };
    }

    case UPDATE_TYPES.AVG_JOURNEY: {
      const { currentHour, previousHour, stations } = data as AvgJourneyData;

      return {
        ...state,
        data: {
          ...state.data,
          currentHourCounts: currentHour,
          previousHourCounts: previousHour,
          stations: updateStations(state.data.stations, stations)
        }
      };
    }

    case UPDATE_TYPES.VEHICLE_COUNT: {
      const { stationId, count } = data as VehicleCountData;
      const stations = state.data.stations.map((station) => {
        if (station.stationId !== stationId) {
          return station;
        }

        return {
          ...station,
          inQueueVehicleCount: count
        };
      });

      return {
        ...state,
        data: {
          ...state.data,
          stations,
          atPremiseVehicleCount: calculateAtPremiseVehicleCount(stations)
        }
      };
    }

    case UPDATE_TYPES.CANCEL_STATION_TIMER: {
      const { stationId,clearAll } = data as CancelStationTimerData;
      const stations = state.data.stations.map((station) => {
        const { _id: eventStationId } = station;

        if (eventStationId !== stationId) {
          return station;
        }
        if(clearAll){
          return {
            ...station,
            vehicles: [],
          };
        }
          // remove the first element from the vehicles array
          const [, ...restVehicles] = station.vehicles;
          return {
            ...station,
            vehicles: restVehicles,
          };
        
      });

      return {
        ...state,
        data: {
          ...state.data,
          stations,
          atPremiseVehicleCount: calculateAtPremiseVehicleCount(stations)
        }
      };
    }

    case UPDATE_TYPES.DEVICE_STATUS: {
      const {  deviceStatus,deviceId } = data as DeviceStatusData;
Logger( deviceStatus,deviceId)
if(state?.data?.deviceId===deviceId){

      return {
        ...state,
        data: {
          ...state.data,deviceStatus:deviceStatus
        }
      }}


      return state
    }

    default:
      return state;
  }
};
