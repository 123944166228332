import { useCallback, useEffect, useRef } from 'react';
import { usePrevious } from '../../Common/Hooks/usePrevious';
import { useSocket } from '../../Common/Hooks/useSocket';
import Logger from '../../Common/Utils/logger';
import { getAppConfig } from '../../Config';
import {
  RoomType,
  SubscribeRoomOptions,
  UnsubscribeRoomOptions
} from '../Types/socket';

export function useCompanyLocationRoomSocket({
  selectedLocationId
}: {
  selectedLocationId: string;
}) {
  const socket = useSocket();
  const previousLocationId = usePrevious<string>(selectedLocationId);
  const previousLocationIdRef = useRef<string | undefined>();
  previousLocationIdRef.current = previousLocationId;

  const subscribeRoom = useCallback(
    (options: SubscribeRoomOptions) => {
      if (!socket) {
        Logger('socket not found while joining', socket);
        return;
      }

      socket.emit('subscribeToRoom', options, (err: Error | null) => {
        if (!err) {
          Logger('joined room', options);
          return;
        }
        Logger('err while joining', options, err);
        // if error
      });
    },
    [socket]
  );

  const unsubscribeRoom = useCallback(
    (options: UnsubscribeRoomOptions) => {
      if (!socket) {
        return;
      }

      socket.emit('unsubscribeFromRoom', options, (err: Error | null) => {
        if (!err) {
          Logger('left room', options);
          return;
        }
        Logger(err);
      });
    },
    [socket]
  );

  const subscribeLocationRoom = useCallback(() => {
    subscribeRoom({
      room: selectedLocationId,
      roomType: RoomType.LOCATION
    });

    Logger('called join ', selectedLocationId);
  }, [selectedLocationId, subscribeRoom, unsubscribeRoom]);

  const unsubscribeLocationRoom = useCallback(() => {
    unsubscribeRoom({ room: selectedLocationId, roomType: RoomType.LOCATION });
    Logger('called left room location ', selectedLocationId);
  }, [selectedLocationId, unsubscribeRoom]);

  const subscribeCompanyRoom = useCallback(() => {
    const { companyId } = getAppConfig();

    if (!companyId) {
      return;
    }

    subscribeRoom({
      room: companyId,
      roomType: RoomType.COMPANY
    });
  }, [subscribeRoom]);

  const unsubscribeCompanyRoom = useCallback(() => {
    const { companyId } = getAppConfig();

    if (!companyId) {
      return;
    }

    unsubscribeRoom({
      room: companyId,
      roomType: RoomType.COMPANY
    });
  }, [subscribeRoom]);

  useEffect(() => {
    const location = previousLocationIdRef.current;

    if (!location) {
      return;
    }

    return () => {
      unsubscribeRoom({
        room: location,
        roomType: RoomType.LOCATION
      });
    };
  }, [unsubscribeRoom]);

  useEffect(() => {
    if (!socket) {
      return;
    }
    subscribeCompanyRoom();
    subscribeLocationRoom();

    return () => {
      unsubscribeLocationRoom();
      unsubscribeCompanyRoom();
    };
  }, [
    socket,
    subscribeLocationRoom,
    subscribeCompanyRoom,
    unsubscribeLocationRoom,
    unsubscribeCompanyRoom
  ]);

  useEffect(() => {
    if (!socket) {
      return;
    }
    const joinRooms = () => {
      subscribeCompanyRoom();
      subscribeLocationRoom();
    };

    const leaveRooms = () => {
      unsubscribeLocationRoom();
      unsubscribeCompanyRoom();
    };

    socket.on('connect', joinRooms);
    socket.on('disconnect', leaveRooms);

    return () => {
      socket.on('connect', joinRooms);
      socket.on('disconnect', leaveRooms);
    };
  }, [
    socket,
    subscribeLocationRoom,
    subscribeCompanyRoom,
    unsubscribeLocationRoom,
    unsubscribeCompanyRoom
  ]);

  return {
    subscribeLocationRoom,
    subscribeCompanyRoom
  };
}
