import React, { SVGProps } from 'react';

interface NightIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const NightIcon = ({
  height = '18',
  width = '18',
  color = 'currentColor',
  ...rest
}: NightIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M8.484 0.682617C7.26319 1.8233 6.50008 3.44765 6.50008 5.25023C6.50008 8.702 9.29833 11.5003 12.7501 11.5003C14.5527 11.5003 16.177 10.7371 17.3177 9.51633C17.0512 13.8783 13.4292 17.3336 9.00008 17.3336C4.39771 17.3336 0.666748 13.6026 0.666748 9.00025C0.666748 4.57118 4.12199 0.949151 8.484 0.682617Z'
        fill={color}
      />
    </svg>
  );
};

export default NightIcon;
