import React, { memo } from 'react';
import RefreshIcon from '../../../Common/Components/Icons/RefreshIcon';
import { useSocketConnection } from '../../Hooks/useSocketConnection';
import styles from './socket-connection-info-bar.module.scss';
import { Loader } from '../../../Common/Components/Loader';
import { MessageInfo } from '../MessageInfo';
import { getAppConfig } from '../../../Config';

export const SocketConnectionInfoBar = memo(
  ({
    deviceOnline,
    edgeDeployed,
    hasDeviceId
  }: {
    deviceOnline: boolean;
    edgeDeployed: boolean;
    hasDeviceId: boolean;
  }) => {
    const {
      socket,
      isConnected,
      connecting: reloading,
      allowReload,
      callConnect
    } = useSocketConnection();
    const socketConnected = isConnected || !socket;
    const isExternalDashboard = !!getAppConfig().isExternalDashboard;
    const renderSocketInfoBar = () => {
      if (
        (socketConnected && deviceOnline) ||
        (!isExternalDashboard && !deviceOnline) ||
        (edgeDeployed && !hasDeviceId)
      ) {
        return null;
      }

      return (
        <div className={styles.infoBar}>
          <div className={styles.barContainer}>
            <span className={styles.infoContainer}>
              <span
                className={
                  socketConnected ? styles.connectedDot : styles.disconnectedDot
                }
              />
              <span className={styles.disconnectedLabel}>
                Socket {socketConnected ? 'Connected' : 'Disconnected'}
              </span>
            </span>
            {!deviceOnline ? (
              <span className={styles.infoContainer}>
                <span className={styles.disconnectedDot} />
                <span className={styles.disconnectedLabel}>Device Offline</span>
              </span>
            ) : null}
            {!socketConnected ? (
              allowReload ? (
                <button
                  className={styles.reloadButton}
                  disabled={reloading || isConnected}
                  onClick={callConnect}
                >
                  <RefreshIcon />
                  <span>Reload</span>
                </button>
              ) : (
                <span className={styles.message}>
                  Please contact your administrator
                </span>
              )
            ) : null}
            {reloading ? (
              <Loader
                style={{ position: 'relative' }}
                loaderStyle={{
                  position: 'absolute',
                  transform: 'translate(-50%, -50%)'
                }}
              />
            ) : null}
          </div>
        </div>
      );
    };

    return (
      <section className={styles.barWrapper}>
        <MessageInfo />
        {renderSocketInfoBar()}
      </section>
    );
  }
);
