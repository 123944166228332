import { ICameraStatusResponse } from '../../../Types';

export const OFFLINE = 'Offline';

export const getOfflineStationCameras = (
  cameraStatusData: ICameraStatusResponse['data'],
  entryId: string,
  exitId: string
) => {
  return cameraStatusData.filter(
    (camera) =>
      camera.status === OFFLINE &&
      (camera._id === entryId || camera._id === exitId)
  );
};

export function convertSeconds(value: number) {
  const seconds = value;
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondsRemaining = Math.round(seconds % 60);
  const dayTxt = days > 1 ? 'days' : 'day';
  const hourTxt = hours > 1 ? 'hours' : 'hour';
  const minTxt = minutes > 1 ? 'mins' : 'min';
  const secTxt = secondsRemaining > 1 ? 'secs' : 'sec';

  if (days) {
    if (hours) {
      return `${days} ${dayTxt} ${hours} ${hourTxt}`;
    }
    return `${days} ${dayTxt}`;
  }

  if (hours) {
    if (minutes) {
      return `${hours} ${hourTxt} ${minutes} ${minTxt}`;
    }
    return `${hours} ${hourTxt}`;
  }

  if (minutes) {
    return `${minutes} ${minTxt}`;
  }

  return `${secondsRemaining} ${secTxt}`;
}
