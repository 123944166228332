import React, { memo, useContext, useEffect, useState } from 'react';
import { calculateTimeDiff } from '../../../Common/Utils/dateTime';
import Events from '../../../Common/Utils/events';
import { ThemeContext } from '../../Constants/themeContext';
import { getProgressBarFillColor } from '../../Utils';
import styles from './progressBar.module.scss';
  
interface IProgressBarProps {
  stationId: string;
  isCircular?: boolean;
  isPrimary?: boolean;
  total?: number;
  finalTitle?: string;
  currentVehicleTime?: number;
  crossCameraStation:boolean;
  numberOfVehicles:number
}
const ProgressBar = memo(
  ({
    stationId,
    isCircular = false,
    isPrimary = false,
    total = 100,
    finalTitle = '-',
    currentVehicleTime,
    crossCameraStation,
    numberOfVehicles
  }: IProgressBarProps) => {
    const theme = useContext(ThemeContext);
    const [{ current = '--:--', currentTime = 0 }, setTime] = useState(
      calculateTimeDiff(currentVehicleTime)
    );

    useEffect(() => {
      if (typeof currentVehicleTime !== 'number') {
        setTime({});
        return;
      }

      const interval = setInterval(() => {
        setTime(calculateTimeDiff(currentVehicleTime));
      }, 100);

      return () => {
        clearInterval(interval);
      };
    }, [currentTime, currentVehicleTime]);

    useEffect(() => {
      setTime(calculateTimeDiff(currentVehicleTime));
    }, [currentVehicleTime]);

    useEffect(() => {
      if(crossCameraStation){
        if (currentTime < 6 * 60 && numberOfVehicles<3) {
          return;
        }
      }else{
        if (currentTime < 15 * 60) {
          return;
        }
      }

      // this will cancel timer 
      Events.notify(Events.type.CANCEL_STATION_TIMER, { stationId});
    }, [currentTime, stationId]);

    const percentage = ((currentTime || 0) / total) * 100;
    const fillColor = currentTime
      ? getProgressBarFillColor(percentage, theme)
      : undefined;

    const renderTimer = ({ className }: { className?: string }) => {
      return (
        <h2
          className={`${className || ''}`}
          style={{
            color: fillColor
          }}
        >
          {current}
        </h2>
      );
    };

    if (isCircular) {
      const rotate = percentage < 100 ? (180 * percentage) / 100 : 180;
      return (
        <div className={styles.circularProgressBar}>
          <div
            className={
              !isPrimary ? styles.barOverflow : styles.largeBarOverflow
            }
          >
            <div
              className={
                !isPrimary ? styles.circularPath : styles.largeCircularPath
              }
            />
            <div
              className={
                !isPrimary
                  ? styles.circularPathDashed
                  : styles.largeCircularPathDashed
              }
            />
            <div
              className={!isPrimary ? styles.bar : styles.largeBar}
              style={{
                transform: `rotate(${rotate + 45}deg)`,
                borderRightColor: fillColor,
                borderBottomColor: fillColor
              }}
            />
          </div>
          {renderTimer({
            className: currentTime < total ? styles.timer : styles.blinkingTimer
          })}
        </div>
      );
    }

    return (
      <div className={styles.progressBar}>
        {current || finalTitle ? (
          <div className={styles.titles}>
            {renderTimer({
              className:
                currentTime >= total ? styles.flashEffect : styles.initialTitle
            })}
            <h4 className={styles.finalTitle}>
              Goal <b>{finalTitle}</b>
            </h4>
          </div>
        ) : null}
        <div className={styles.path}>
          <div
            className={styles.filled}
            style={{
              width: `${percentage >= 100 ? 100 : percentage}%`,
              backgroundColor: fillColor
            }}
          />
        </div>
      </div>
    );
  }
);

export default ProgressBar;
