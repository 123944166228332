import React, { SVGProps } from 'react';

interface GoalFlagIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const GoalFlagIcon = ({
  height = '20',
  width = '20',
  color = 'currentColor',
  ...rest
}: GoalFlagIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 1.86017C0.981943 0.59042 4.307 -1.29897 9.75171 1.30148C14.7994 3.71233 16.5738 2.52613 17.6388 1.81422C17.7225 1.75823 17.8019 1.70517 17.8781 1.65703C18.9236 0.996756 19.5034 1.65703 19.4526 1.91098V14.7609C17.7258 16.183 14.526 17.8083 8.88827 14.7609C3.80926 12.5769 1.70361 14.6085 1.62528 14.7609C1.62528 14.7609 1.67612 18.7733 1.62528 19.4844C1.42217 20.1954 0 20.1954 0 19.332V1.86017ZM6.95824 12.4047V6.93848C9.12812 7.74646 10.3534 8.23224 12.5451 9.12244L12.5451 14.557C13.9672 15.2685 16.3544 15.0143 17.9289 13.9477V8.56396C15.8973 9.52898 14.5259 9.57979 12.5451 9.07188L12.5451 4.04342C10.5289 3.31445 9.32556 2.82408 7.46823 2.06722L6.95819 1.85945V6.9194C4.57108 6.10664 2.9966 6.71637 1.57449 7.42785V12.8116C3.57062 11.964 4.75524 11.9143 6.95824 12.4047Z'
        fill={color}
      />
    </svg>
  );
};

export default GoalFlagIcon;
