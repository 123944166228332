import { Theme } from '../Types/theme';

const OD_THEME_KEY = 'wobot-operational-dashboard-theme';

export const getThemeColors = (selectedTheme: Theme) =>
  ({
    [Theme.light]: {
      '--bg-color': '#ffffff',
      '--progress-bar-border-color': '#f9f9f9',
      '--station-card-border-color': '#f0f0f0',
      '--text-color': '#545454',
      '--light-text-color': '#000000',
      '--road-color': '#F9F9F9',
      '--green-color': '#029262',
      '--highlight-color': '#F9F9F9',
      '--primary-highlight-color': '#F0F0F0',
      '--theme-button-bg-color': '#CED4DA',
      '--active-shift-highlight': '#F0F0F0',
      '--filter-background':"#F9F9F9",
      '--primary-card-background':"#F0F0F0",
      '--road-background':"#F0F0F0",
      '--additional-vehicle-background':"#F0F0F0",

    },
    [Theme.dark]: {
      '--bg-color': '#0A0A0C',
      '--progress-bar-border-color': '#000000',
      '--station-card-border-color': '#000000',
      '--text-color': '#CED4DA',
      '--light-text-color': '#FFFFFF',
      '--road-color': '#0A0A0C',
      '--green-color': '#5AD052',
      '--highlight-color': '#242527',
      '--primary-highlight-color': '#3F4042',
      '--theme-button-bg-color': '#242527',
      '--active-shift-highlight': '#0A0A0C',
      '--filter-background':"#242527",
      '--primary-card-background':"#3F4042",
      '--road-background':"#0A0A0C",
      '--additional-vehicle-background':"",

    }
  }[selectedTheme]);

export const setThemeColors = (colors: Record<string, string>) => {
  if (!colors) {
    return;
  }

  Object.keys(colors).forEach((colorName) => {
    document.documentElement.style.setProperty(colorName, colors[colorName]);
  });
};

export const getSelectedTheme = (): Theme => {
  let selectedTheme = localStorage.getItem(OD_THEME_KEY) as Theme;
  if (!selectedTheme) {
    selectedTheme = Theme.dark;
  }
  setSelectedTheme(selectedTheme);
  return selectedTheme;
};

export const setSelectedTheme = (selectedTheme: Theme) => {
  localStorage.setItem(OD_THEME_KEY, selectedTheme);
  setThemeColors(getThemeColors(selectedTheme));
};
