import { useDashboardTheme } from "../../OperationalDashboard/Hooks/useDashboardTheme";
import { Theme } from "../../OperationalDashboard/Types/theme";

interface ControlProps {
    isDisabled?: boolean;
    isSelected?: boolean;
    isFocused?: boolean;
  }
  
  export interface ColourStyles {
    [key: string]: (base: Object, props: ControlProps) => Object;
  }
  
  export const colourStyles: ColourStyles = {
    control: (base, { isDisabled, isSelected, isFocused }) => ({
      ...base,
      backgroundColor:"unset !important",
      borderColor: isFocused
        ? `${getColorsByTheme("border")} !important`
        : isSelected
        ? `${getColorsByTheme("border")} !important`
        : isDisabled
        ? `${getColorsByTheme("border")} !important`
        : `${getColorsByTheme("border")} !important`,
      borderWidth: "1px !important",
      borderStyle: "solid !important",
      transition: "unset !important",
      boxShadow: isFocused
        ? "unset !important"
        : "unset !important",
      borderRadius: "6px !important",
      
    }),
    singleValue: (base) => ({
        ...base,
        color:`${getColorsByTheme("optionColorSelected")} !important`
      }),
    menu: (base) => ({
        ...base,
        backgroundColor: getColorsByTheme("menuBackground"),
        borderWidth: "2px !important",
        borderStyle: "solid !important",
        borderColor:getColorsByTheme("menuBorder")

      }),
    option: (styles: Object, { isDisabled, isSelected, isFocused }: ControlProps) => {
      
        return {
      ...styles,
      cursor: isDisabled ? "auto" : "pointer",
      fontSize: isDisabled ? "10px" : "14px",
      fontWeight:"400",
      textTransform: isDisabled ? "uppercase" : "unset",
      letterSpacing: isDisabled ? "1.5px" : "unset",
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? `${getColorsByTheme("optionSelected")} `
        : isFocused
        ? `${getColorsByTheme("optionFocused")} `
        : undefined,
        
      color: isDisabled
        ? undefined
        : isSelected
        ? getColorsByTheme("optionColorSelected")
        : isFocused
        ? getColorsByTheme("optionColorFocused")
        : undefined,
        zIndex: 1,
    }},
   
  };
  
 
  function getColorsByTheme(type:string): string {
    const { theme } = useDashboardTheme();
   const dark=theme===Theme.dark

    const styles:Record<string, string>={
        background:dark?'#0A0A0C':'#ffffff',
        border:dark?'#0A0A0C':'#ffffff',
        optionSelected:dark?'#3F4042' : '#F0F0F0',
        optionFocused:dark?'rgba(63, 64, 66, 0.5)' : '#F0F0F0',
        optionColorSelected:dark?'#CED4DA':'#545454',
        menuBackground:dark?'#242527':'#F9F9F9',
        menuBorder:dark?'#3F4042':'#F0F0F0',
        controlBackground:dark?"#0A0A0C":"#ffffff"
    }

    return styles[type]


  }