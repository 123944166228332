import { request } from '../../Common/Requests/request';
import { getAppConfig } from '../../Config';
import { ILeaderboardResponse } from '../Types';

export const getLeaderboardData = async ({
  activeShiftId,
  timezone
}: {
  activeShiftId: string;
  timezone: string | undefined;
}): Promise<ILeaderboardResponse> => {
  const apiResponse = await request<ILeaderboardResponse>({
    url: `${
      getAppConfig().leaderboardApi || ''
    }?shift=${activeShiftId}&timezone=${timezone}`
  });

  return apiResponse;
};
