import React, { SVGProps } from 'react';

interface RankIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const RankIcon = ({
  height = '28',
  width = '28',
  color = 'currentColor',

  ...rest
}: RankIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M13.9993 8.16653C19.154 8.16653 23.3327 12.3452 23.3327 17.4998C23.3327 22.6545 19.154 26.8332 13.9993 26.8332C8.84469 26.8332 4.66602 22.6545 4.66602 17.4998C4.66602 12.3452 8.84469 8.16653 13.9993 8.16653ZM13.9993 12.2498L12.4564 15.3762L9.00631 15.8775L11.5028 18.311L10.9135 21.7472L13.9993 20.1248L17.0852 21.7472L16.4959 18.311L18.9924 15.8775L15.5423 15.3762L13.9993 12.2498ZM15.166 2.33203L20.9993 2.3332V5.8332L19.4087 7.16035C18.1183 6.48386 16.6856 6.04186 15.1673 5.89094L15.166 2.33203ZM12.8327 2.33203L12.8323 5.89084C11.3141 6.04161 9.88155 6.48345 8.59116 7.15974L6.99935 5.8332V2.3332L12.8327 2.33203Z'
        fill={color}
      />
    </svg>
  );
};

export default RankIcon;
