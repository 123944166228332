import React, { SVGProps } from 'react';

interface RefreshIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const RefreshIcon = ({
  height = '13',
  width = '10',
  color = 'currentColor',
  ...rest
}: RefreshIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 10 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M8.75 7.625C8.75 9.69375 7.06875 11.375 5 11.375C2.93125 11.375 1.25 9.69375 1.25 7.625C1.25 5.55625 2.93125 3.875 5 3.875V6.375L8.125 3.25L5 0.125V2.625C2.2375 2.625 0 4.8625 0 7.625C0 10.3875 2.2375 12.625 5 12.625C7.7625 12.625 10 10.3875 10 7.625H8.75Z'
        fill={color}
      />
    </svg>
  );
};

export default RefreshIcon;
