import { ILeaderboardResponse } from '../Types';
import { useCallback, useEffect, useState } from 'react';
// Assuming you're not using useMakeRequest here
import { getLeaderboardData } from '../Requests/getLeaderboardData';

function useGetLeaderboardData({
  activeShiftId,
  timezone
}: {
  activeShiftId: string;
  timezone: string | undefined;
}) {
  const [leaderboardData, setLeaderboardData] = useState<
    ILeaderboardResponse['data']
  >([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchLeaderboardData = useCallback(async () => {
    // setLoading(true);
    setError(null); // Reset error before each fetch
    try {
      const response = await getLeaderboardData({ activeShiftId, timezone });
      setLeaderboardData(response.data || []);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const fetchDataWithInterval = async () => {
      await fetchLeaderboardData();
    };

    fetchDataWithInterval();

    const intervalId = setInterval(fetchDataWithInterval, 5 * 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [fetchLeaderboardData]);

  return {
    loading,
    error,
    leaderboardData
  };
}

export default useGetLeaderboardData;
