import { ILeaderboardResponse } from '../../../Types';
import styles from '../StationTypeStats.module.scss';

export function getRankColor(index: number) {
  switch (index) {
    case 0:
      return '#FFC107';
    case 1:
      return '#CED4DA';
    case 2:
      return '#E2AB80';
    default:
      return '#FFC107';
  }
}

export function getLeaderboardItems(
  data: ILeaderboardResponse['data'],
  locationId: string
) {
  let leaderboardLocations = data.map((location, index) => {
    return { ...location, rank: index };
  });
  const currentLocation = leaderboardLocations.find(
    (location) => location._id.location === locationId
  );

  leaderboardLocations = leaderboardLocations.slice(0, 5);

  if (
    currentLocation &&
    currentLocation.rank >= 0 &&
    currentLocation.rank > 4
  ) {
    leaderboardLocations = leaderboardLocations.slice(0, 4);
    leaderboardLocations.push(currentLocation);
  }

  return leaderboardLocations;
}

export function getLocationBoxClass(
  currentLocation: string,
  locationId: string,
  rank: number
) {
  if (currentLocation === locationId) {
    if (rank > 4) {
      return styles.currentLocationOutOfRank;
    }
    return styles.currentLocationBox;
  }

  return styles.locationBox;
}

//
