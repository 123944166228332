import React, { SVGProps } from 'react';

interface PickUpIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const PickUpIcon = ({
  height = '14',
  width = '14',
  color = 'currentColor',
  ...rest
}: PickUpIconProps) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox='0 0 20 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M19.9 5.89L18.85 1.52C18.63 0.62 17.85 0 16.94 0H3.05001C2.15001 0 1.36001 0.63 1.15001 1.52L0.100007 5.89C-0.139993 6.91 0.0800067 7.95 0.720007 8.77C0.800007 8.88 0.910007 8.96 1.00001 9.06V16C1.00001 17.1 1.90001 18 3.00001 18H17C18.1 18 19 17.1 19 16V9.06C19.09 8.97 19.2 8.88 19.28 8.78C19.92 7.96 20.15 6.91 19.9 5.89ZM16.91 1.99L17.96 6.36C18.06 6.78 17.97 7.2 17.71 7.53C17.57 7.71 17.27 8 16.77 8C16.16 8 15.63 7.51 15.56 6.86L14.98 2L16.91 1.99ZM11 2H12.96L13.5 6.52C13.55 6.91 13.43 7.3 13.17 7.59C12.95 7.85 12.63 8 12.22 8C11.55 8 11 7.41 11 6.69V2ZM6.49001 6.52L7.04001 2H9.00001V6.69C9.00001 7.41 8.45001 8 7.71001 8C7.37001 8 7.06001 7.85 6.82001 7.59C6.57001 7.3 6.45001 6.91 6.49001 6.52ZM2.04001 6.36L3.05001 2H5.02001L4.44001 6.86C4.36001 7.51 3.84001 8 3.23001 8C2.74001 8 2.43001 7.71 2.30001 7.53C2.03001 7.21 1.94001 6.78 2.04001 6.36ZM3.00001 16V9.97C3.08001 9.98 3.15001 10 3.23001 10C4.10001 10 4.89001 9.64 5.47001 9.05C6.07001 9.65 6.87001 10 7.78001 10C8.65001 10 9.43001 9.64 10.01 9.07C10.6 9.64 11.4 10 12.3 10C13.14 10 13.94 9.65 14.54 9.05C15.12 9.64 15.91 10 16.78 10C16.86 10 16.93 9.98 17.01 9.97V16H3.00001Z'
        fill={color}
      />
    </svg>
  );
};

export default PickUpIcon;
