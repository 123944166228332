export interface ILocationLayoutRequest {
  location: string;
  companyId?: string;
}

export enum StationStatus {
  ACTIVE = 'Active'
}

export enum StationType {
  ENTRY = 'Entry',
  MENU = 'Menu',
  PICKUP = 'Pickup',
  WINDOW = 'Window'
}

export enum AccessPointType {
  ENTRY = 'Entry',
  EXIT = 'Exit'
}

export enum ProcessingZone {
  ENTRY = 'Entry',
  PROCESSING = 'Processing',
  EXIT = 'Exit'
}

export interface Camera {
  _id: string;
  camera: string;
  thumbnail: string;
}

export interface AccessPoint {
  camera: Camera;
  time: AccessPointType;
}
export interface vehiclesData {
  currentVehicleTime: number;
  uid: String;
}
export interface StationLayoutData {
  entry: AccessPoint;
  exit: AccessPoint;
  goalFormatted: string;
  goal: number;
  label?: string;
  halt?: boolean;
  primary?: boolean;
  orderNo: number;
  processingZone: ProcessingZone;
  station: string;
  stationId: string;
  stationTypeId: string;
  status: StationStatus;
  type: StationType;
  __v: number;
  _id: string;
  currentVehicleTime?: number;
  currentVehicleUid?: string;
  avgTime?: number;
  lastHourGoalMet?: number; // [0-100]
  inQueueVehicleCount?: number;
  vehicles: Array<vehiclesData>;
  crossCameraStation: boolean;
}

export type Stations = Array<StationLayoutData>;

export interface LocationData {
  area: string;
  timezone: string;
}

export interface JourneyConfig {
  runningServices: string[] | undefined;
  journeyEnvironment: string | undefined;
}
export interface LocationDataConfig {
  stationDisplayOrder: string;
  enableShiftOnOD: boolean;
  runningServices: string[];
  status: string;
  deploymentType: string;
  environment: string;
  enableLeaderBoardOnOD: boolean;
}

export interface ILocationLayoutResponse {
  status: number;
  data: {
    deviceStatus: string;
    deviceId: string;
    stations: Stations;
    config: LocationDataConfig;
    location: LocationData;
    timezone: string;
    atPremiseVehicleCount?: number;
    currentHourCounts?: number;
    previousHourCounts?: number;
  };
}

export interface ILocationLayoutRawResponse {
  status: number;
  data: {
    stations: Stations;
    stationType: Array<{ type: StationType; primary: boolean }>;
    location: LocationData;
    timezone: string;
    atPremiseVehicleCount?: number;
    currentHourCounts?: number;
    previousHourCounts?: number;
  };
}

// ROAD MAP
export interface RoadMapChildrenData {
  rows: Array<Stations>;
}

// Filter interface

export interface IFilter {
  value: string;
  label: string;
}

// Camera Status interface

export type CameraIds = Array<string>;

export interface cameraStatusData {
  _id: string;
  status: string;
  lastActive: number;
}

export interface ICameraStatusResponse {
  status: number;
  data: cameraStatusData[];
}

export interface leaderboardData {
  averageJourneyTime: number;
  averageJourneyTimeFormat: string;
  dateToString: string;
  goal: string;
  rank: number;
  goalScore: number;
  location: string;
  totalJourneyTime: number;
  totalJourneyTimeFormat: string;
  totalVehicle: number;
  _id: { location: string };
}

export interface ILeaderboardResponse {
  status: number;
  data: leaderboardData[];
}
