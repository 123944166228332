import { request } from '../../Common/Requests/request';
import { getAppConfig } from '../../Config';
import { CameraIds, ICameraStatusResponse } from '../Types';



export const getCameraStatusData = async ({cameraIds}:{cameraIds:CameraIds}): Promise<ICameraStatusResponse> => {
  const isExternalDashboard = getAppConfig().isExternalDashboard;
 const requestBody:{ids:CameraIds,fields?:string[]}={ids:cameraIds}
if(!isExternalDashboard){
  requestBody.fields=["status","lastActive"]
}

  const apiResponse = await request<ICameraStatusResponse>({
    url: `${
      getAppConfig().cameraStatusApi || ''
    }`,
    method:"POST",
    body:requestBody
  });

  return apiResponse;
};
