import { CameraIds, ICameraStatusResponse } from '../Types';
import { useCallback, useEffect, useState } from 'react';
// Assuming you're not using useMakeRequest here
import { getCameraStatusData } from '../Requests/getCameraStatusData';

function useCameraStatusData( cameraIds:CameraIds ) {
  const [cameraStatusData, setCameraStatusData] = useState<ICameraStatusResponse['data']>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchCameraStatusData = useCallback(async () => {
    setLoading(true);
    setError(null); // Reset error before each fetch
    try {
      const response = await getCameraStatusData({ cameraIds });
      setCameraStatusData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const fetchDataWithInterval = async () => {
      await fetchCameraStatusData();
    };

    fetchDataWithInterval();

    const intervalId = setInterval(fetchDataWithInterval, 5 *60 *1000 ); 

    return () => {
      clearInterval(intervalId);
    };
  }, [fetchCameraStatusData]); 

  return {
    loading,
    error,
    cameraStatusData,
  };
}

export default useCameraStatusData;
