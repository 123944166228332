import React, { memo } from 'react';
import styles from './StationTypeStats.module.scss';
import useGetLeaderboardData from '../../Hooks/useGetLeaderboardData';
import RankIcon from '../../../Common/Components/Icons/RankIcon';
import {
  getLeaderboardItems,
  getLocationBoxClass,
  getRankColor
} from './Utils';
import HourglassIcon from '../../../Common/Components/Icons/HourglassIcon';
import GoalMetIcon from '../../../Common/Components/Icons/GoalMetIcon';
interface Leaderboard {
  activeShiftId: string;
  location: string;
  enableLeaderBoardOnOD: boolean | undefined;
  timezone: string | undefined;
}

export const LeaderboardComponent: React.FC<Leaderboard> = memo(
  ({
    activeShiftId,
    location: currentLocation,
    enableLeaderBoardOnOD,
    timezone
  }: Leaderboard) => {
    const { leaderboardData, loading } = useGetLeaderboardData({
      activeShiftId: activeShiftId,
      timezone: timezone
    });
    const leaderboardLocations = getLeaderboardItems(
      leaderboardData,
      currentLocation
    );
    const locationCount = leaderboardLocations.length;
    return !loading && enableLeaderBoardOnOD ? (
      <div className={styles.container}>
        <p className={styles.leaderboardTitle}>Leaderboard (Window Time)</p>
        {leaderboardData?.length && activeShiftId ? (
          <div className={styles.locationBoxContainer}>
            {leaderboardLocations.map((location) => {
              return (
                <div
                  className={`${getLocationBoxClass(
                    currentLocation,
                    location._id.location,
                    location.rank
                  )} ${locationCount === 5 ? styles.flexLocationBox : ''}`}
                  key={location.location}
                >
                  <span className={styles.locationNameWrapper}>
                    {location.rank > 2 ? (
                      <p className={styles.locationRank}>
                        {location.rank + 1}.
                      </p>
                    ) : (
                      <RankIcon color={getRankColor(location.rank)} />
                    )}{' '}
                    <p className={styles.locationName}>{location.location}</p>
                  </span>
                  <div className={styles.locationStats}>
                    <span>
                      {' '}
                      <HourglassIcon />
                      <p> {location.averageJourneyTimeFormat}</p>
                    </span>
                    <span>
                      <GoalMetIcon /> <p>{location.goal}%</p>
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={styles.leaderboardZeroState}>
            <p>No data available for current shift</p>
          </div>
        )}
      </div>
    ) : null;
  }
);
