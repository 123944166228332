import { getAppConfig } from '../../../../Config';
import { JourneyConfig } from '../../../Types';
const JOURNEY = 'thruputJourney';
const PUBLISHED = 'Published';
export function getShowJourneyData(journeyConfig: JourneyConfig | undefined) {
  const showJourneyDataAuth = getAppConfig().showJourneyDataAuth;
  let showJourneyData = false;
  if (journeyConfig?.runningServices?.includes(JOURNEY)) {
    if (journeyConfig?.journeyEnvironment === PUBLISHED) {
      showJourneyData = true;
    } else if (showJourneyDataAuth) {
      showJourneyData = true;
    }
  }
  return { showJourneyData: showJourneyData };
}

export const updateUrl = (param: string, value: string) => {
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set(param, value);
  window.history.replaceState({}, '', currentUrl.pathname + currentUrl.search);
};
