import { getAppConfig } from '../../Config';

function getEnvironmentFromUrl(): string {
  const currentUrl = window.location.href;

  if (currentUrl.includes("dev.wobot")) {
    return "https://dev.wobot.ai/home";
  } else if (currentUrl.includes("staging.wobot")) {
    return "https://staging.wobot.ai/home";
  } else if (currentUrl.includes("app.wobot")) {
    return "https://app.wobot.ai/home";
  }
  return ""
}

export async function request<T>({
  url,
  method = 'GET',
  headers,
  body // Optional body parameter
}: {
  url: string;
  method?: 'GET' | 'POST';
  headers?: RequestInit['headers'];
  body?: any; 
}): Promise<T> {
  const {
    authToken = '',
    device = '',
    deviceId = '',
    deviceType = '',
    timezone = '',
    companyId = '',
    isExternalDashboard = false
  } = getAppConfig();

  const fetchOptions: RequestInit = {
    method,
    headers: {
      token: authToken,
      device,
      'device-id': deviceId,
      'device-type': deviceType,
      tz: timezone,
      company: companyId,
      ...headers
    }
  };

  
  if (method === 'POST' && body) {
    fetchOptions.body = JSON.stringify(body);
    fetchOptions.headers = {
      ...fetchOptions.headers,
      'Content-Type': 'application/json' 
    };
  }

  const response = await fetch(`${getAppConfig().baseApiUrl}${url}`, fetchOptions);

  const data = await response.json();

  if (
    !data ||
    response.status === 500 ||
    response.status === 401 ||
    response.status === 403 ||
    response.status === 404
  ) {
    const homeUrl = getEnvironmentFromUrl();
    if (isExternalDashboard && homeUrl) {
      window.location.href = homeUrl;
    }
    throw data;
  }

  return data as T;
}

