import { useState } from 'react';
import { IFilter } from '../Types';

interface UseGetLocationFilter{
  filterlocation: IFilter;
  setFilterlocation: (location: IFilter) => void;
  
}

const useGetLocationFilter = (locationId: string,locationFilterData?:IFilter[]): UseGetLocationFilter => {
  const initialLocation=locationFilterData?.filter((location)=>location.value===locationId)[0] || {value:locationId,label: "Location"}
  const [filterlocation, setFilterlocation] = useState(initialLocation);

// Logger(locationId)

  return { filterlocation, setFilterlocation };
};

export default useGetLocationFilter;
