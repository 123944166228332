
const options: Intl.DateTimeFormatOptions = {
  // year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
  second: '2-digit',
  hour12: true
};

export const timeFormat = function (seconds: number) {
  let formattedSeconds: number | string = Math.round(seconds);
  let hours: number | string = Math.floor(formattedSeconds / 3600);
  let minutes: number | string = Math.floor(
    (formattedSeconds - hours * 3600) / 60
  );

  formattedSeconds = formattedSeconds - hours * 3600 - minutes * 60;

  hours = hours ? (hours < 10 ? ('0' + hours).slice(-2) : hours) : hours;
  minutes = minutes < 10 ? ('0' + minutes).slice(-2) : minutes;
  formattedSeconds =
    formattedSeconds < 10
      ? ('0' + formattedSeconds).slice(-2)
      : formattedSeconds;

  return `${hours ? hours + ':' : ''}${minutes ? minutes + ':' : ''}${
    formattedSeconds || ''
  }`;
};

export const now = () => new Date().getTime();

const formatDate = (date: Date, options: Intl.DateTimeFormatOptions) =>
  new Intl.DateTimeFormat('en-US', options).format(date);

export const formatDateAndTime = (time?: number, timeZone?: string) => {
  const date = time ? new Date(time) : new Date();

  const { month, day, hour, minute, second, hour12, year } = options;

  return {
    date: formatDate(date, { month, day, year, timeZone }),
    time: formatDate(date, {
      hour,
      minute,
      second,
      hour12,
      timeZone
    })
  };
};

const second = 1000;
const minute = second * 60;
const hour = minute * 60;
const day = 24 * hour;

export const calculateTimeDiff = (t?: number) => {
  if (typeof t !== 'number') {
    return {};
  }

  const diff = now() - t;
  const days = Math.floor(diff / day);

  if (diff < 0) {
    return {};
  }

  if (days > 0) {
    return {
      currentTime: diff / 1000,
      current: `${days} days`
    };
  }

  return {
    currentTime: diff / 1000,
    current: timeFormat(diff / 1000)
  };
};
