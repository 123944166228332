import React from 'react';
import styles from './dashboard-unavailable.module.scss';
import DeviceOfflineIcon from '../../../Common/Components/Icons/DeviceOfflineIcon';

const DashboardUnavailable = () => {
  return (
    <div className={styles.offlineContainerWrapper}>
      <DeviceOfflineIcon />

      <span className={styles.textWrapper}>
        <p>DriveThru tracking is currently not running on this location.</p>
        <p>Contact support for more information.</p>
      </span>
    </div>
  );
};

export default DashboardUnavailable;
