import { useEffect, useState } from 'react';
// 60000ms = 1 min (flipping after 1 min)
const useGetHidden = (initialValue = false, intervalTime = 60000):boolean => {
  const [isHidden, setIsHidden] = useState<boolean>(initialValue);

  const handleFlip = () => {
    setIsHidden((prevIsHidden) => !prevIsHidden);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleFlip();
    }, intervalTime);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return isHidden;
};

export default useGetHidden;
