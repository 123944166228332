import { useCallback, useEffect, useRef, useState } from 'react';
import { useSocket } from '../../Common/Hooks/useSocket';

const MAX_RETRY_COUNT = 3;

export const useSocketConnection = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  const retryCountRef = useRef(retryCount);
  retryCountRef.current = retryCount;

  const onConnect = useCallback(() => {
    setIsConnected(true);
  }, []);

  const socket = useSocket(undefined, onConnect);

  const allowReload = retryCount < MAX_RETRY_COUNT;

  const callConnect = useCallback(() => {
    if (!socket || !allowReload || connecting) {
      return;
    }

    setConnecting(true);
    socket.connect();
  }, [socket, connecting]);

  useEffect(() => {
    if (connecting) {
      return;
    }

    setRetryCount(retryCountRef.current + 1);
  }, [connecting]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    const onConnect = () => {
      setConnecting(false);
      setRetryCount(-1);
    };
    const onDisconnect = () => setIsConnected(false);
    const onConnectionError = () => setConnecting(false);

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('onerror', onConnectionError);
    socket.on('connect_error', onConnectionError);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('onerror', onConnectionError);
      socket.off('connect_error', onConnectionError);
    };
  }, [socket]);

  return { isConnected, socket, connecting, allowReload, callConnect };
};
