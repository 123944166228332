import { useEffect, useRef, useState } from 'react';
import { now } from '../Utils/dateTime';
import Events, { EventType } from '../Utils/events';
import Logger from '../Utils/logger';
import { getClientSocket } from '../Utils/socket';

enum DISCONNECTION_ERROR {
  TRANSPORT_ERROR = 'transport error'
}

export function useSocket(
  onReconnect?: (lastUpdatedTime: number) => void,
  onConnection?: () => void
) {
  const [socket] = useState(getClientSocket());
  const lastUpdatedTimeRef = useRef(now());

  useEffect(() => {
    if (!socket) {
      return;
    }

    const onConnect = () => {
      if (onConnection) {
        onConnection();
      }

      Logger('connected', socket.id);
    };

    const onDisconnect = (error: DISCONNECTION_ERROR) => {
      Logger('socket disconnected');
      lastUpdatedTimeRef.current = now();

      if (error === DISCONNECTION_ERROR.TRANSPORT_ERROR) {
        socket.connect();
      }
    };

    const onError = (...args: any[]) => {
      Logger('Authentication failed', ...args);
      const [data] = args;
      const error = data as Error;

      if (error?.message === 'unknown user') {
        Events.notify(EventType.AUTHENTICATION_FAILED, ...args);
      }
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('connect_error', onError);

    if (!socket.connected) {
      socket.connect();
    }

    return () => {
      if (socket.connected) {
        socket.disconnect();
      }
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('connect_error', onError);
    };
  }, [socket, onConnection]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    const onOnline = () => {
      socket.connect();

      if (typeof onReconnect === 'function') {
        onReconnect(lastUpdatedTimeRef.current);
      }
    };

    const onOffline = () => {
      lastUpdatedTimeRef.current = now();
    };

    window.addEventListener('offline1', onOffline);
    window.addEventListener('online1', onOnline);
    window.addEventListener('onfocus1', onOnline);

    return () => {
      window.removeEventListener('offline1', onOffline);
      window.removeEventListener('online1', onOnline);
      window.removeEventListener('onfocus1', onOnline);
    };
  }, [socket, onReconnect]);

  return socket;
}
