import React, { SVGProps } from 'react';

interface HourglassIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const HourglassIcon = ({
  width = '12',
  height = '12',
  color = 'currentColor',
  ...rest
}: HourglassIconProps) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M4.99967 3.33317H3.33301V1.6665H16.6663V3.33317H14.9997V4.99984C14.9997 6.34603 14.3195 7.42874 13.4631 8.31373C12.8773 8.919 12.1647 9.4765 11.4421 9.99984C12.1647 10.5232 12.8773 11.0807 13.4631 11.6859C14.3195 12.5709 14.9997 13.6537 14.9997 14.9998V16.6665H16.6663V18.3332H3.33301V16.6665H4.99967V14.9998C4.99967 13.6537 5.67981 12.5709 6.53626 11.6859C7.12202 11.0807 7.83469 10.5232 8.55726 9.99984C7.83469 9.4765 7.12202 8.919 6.53626 8.31373C5.67981 7.42874 4.99967 6.34603 4.99967 4.99984V3.33317ZM6.66634 3.33317V4.99984C6.66634 5.57079 6.88322 6.11233 7.3091 6.6665H12.6903C13.1161 6.11233 13.333 5.57079 13.333 4.99984V3.33317H6.66634ZM9.99967 11.0181C9.12867 11.6333 8.33967 12.219 7.73392 12.845C7.57474 13.0095 7.43307 13.1718 7.3091 13.3332H12.6903C12.5663 13.1718 12.4246 13.0095 12.2654 12.845C11.6597 12.219 10.8707 11.6333 9.99967 11.0181Z'
        fill={color}
      />
    </svg>
  );
};

export default HourglassIcon;
