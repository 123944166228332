import React, { SVGProps } from 'react';

interface EntryIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const EntryIcon = ({
  width = '12',
  height = '12',
  color = 'currentColor',
  ...rest
}: EntryIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 15 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M6.0001 16.0003L15 16.0004L15 14.0004L8.0001 14.0003L8 3.82845L11.9497 7.7782L13.3639 6.36396L7 -4.4509e-07L0.635999 6.36396L2.0502 7.7782L6 3.8284L6.0001 16.0003Z'
        fill={color}
      />
    </svg>
  );
};

export default EntryIcon;
