import { AccessPointType } from '.';

export enum UPDATE_TYPES {
  DETECTION = 'DETECTION',
  CANCEL_STATION_TIMER = 'CANCEL_STATION_TIMER',
  AVG_JOURNEY = 'AVG_JOURNEY',
  VEHICLE_COUNT = 'VEHICLE_COUNT',
  DEVICE_STATUS = 'DEVICE_STATUS'
}

export type CancelStationTimerData = {
  stationId: string;
  clearAll?:boolean;
};

export interface DetectionEventData {
  cameraId: string;
  cameraName: string;
  company: string;
  location: string;
  detectionConf: number;
  detection: number[][];
  locationName: string;
  payloadType: string;
  stationName: string;
  stationType: string;
  stationId: string;
  stationOrder: number;
  time: string; // ISO DATE
  uid: string;
  triggerType: AccessPointType;
  _id: string;
  id: string;
}

export interface VehicleCountData {
  payloadType: string;
  location: string;
  stationId: string;
  count: number;
}

export interface AvgJourneyData {
  currentHour: number;
  previousHour: number;
  stations: Array<{
    stationId: string;
    orderNo: number;
    location: string;
    lastHourGoalMet: number;
    avgTime: number;
  }>;
}
export interface DeviceStatusData {
  deviceStatus:string;
  deviceId:string;
}

export interface DetectionSocketEventPayload {
  error?: Error;
  message?: string;
  data: DetectionEventData;
}

export interface VehicleSocketEventPayload {
  error?: Error;
  message?: string;
  data: VehicleCountData;
}

export interface AvgJourneySocketEventPayload {
  error?: Error;
  message?: string;
  data: AvgJourneyData;
}
export interface deviceStatusEventPayload{
  error?: Error;
  message?: string;
  data: DeviceStatusData;
}

export type SocketUpdate =
  | { type: UPDATE_TYPES.DETECTION; data: DetectionEventData }
  | { type: UPDATE_TYPES.CANCEL_STATION_TIMER; data: CancelStationTimerData }
  | { type: UPDATE_TYPES.AVG_JOURNEY; data: AvgJourneyData }
  | { type: UPDATE_TYPES.VEHICLE_COUNT; data: VehicleCountData }
  | { type: UPDATE_TYPES.DEVICE_STATUS; data: DeviceStatusData};

export enum RoomType {
  LOCATION = 'location',
  COMPANY = 'company'
}

export interface SubscribeRoomOptions {
  room: string; // '<locationId|companyId>',
  roomType: RoomType;
  leaveRoom?: string; // '<locationId|companyId>'
}

export interface UnsubscribeRoomOptions {
  room: string;
  roomType: RoomType;
}
