import { StationType, Stations } from '../Types';
import { Theme } from '../Types/theme';

export const COLORS = {
  RED: '#DC3545',
  YELLOW: '#FF7E17',
  GREEN: '#029262',
  DARK_GREEN: '#57D109'
};

export const DEVICE_ONLINE="Online";
export const TASK_ACTIVE="Active";
export const EDGE="edge";

const isDarkTheme = (theme: Theme) => {
  return theme === Theme.dark;
};

export const getProgressBarFillColor = (percentage: number, theme: Theme) => {
  if (percentage >= 100) {
    return COLORS.RED;
  }

  if (percentage >= 75) {
    return COLORS.YELLOW;
  }

  return isDarkTheme(theme) ? COLORS.DARK_GREEN : COLORS.GREEN;
};

export const getGoalMetTextColor = (goalMet?: number) => {
  if (typeof goalMet !== 'number' || goalMet > 75) {
    return COLORS.GREEN;
  }

  if (goalMet > 50) {
    return COLORS.YELLOW;
  }

  return COLORS.RED;
};

export const getAvgTimeTextColor = (percent: number) => {
  if (percent >= 100) {
    return COLORS.RED;
  }

  if (percent >= 75) {
    return COLORS.YELLOW;
  }

  return COLORS.GREEN;
};

export const groupByStationType = (stations: Stations) => {
  const formattedStations: Stations[] = [];

  let prevStationType: StationType | null = null;

  // reverse ? [...stations].reverse() : stations
  [...stations].forEach((station) => {
    const { type } = station;

    if (prevStationType !== type) {
      formattedStations.push([]);
    }

    formattedStations[formattedStations.length - 1].push(station);
    prevStationType = type;
  });

  // Logger(formattedStations);
  return formattedStations;
};

export function extractCameraIds(data: Stations | undefined): string[] {
  const cameraIdsSet = new Set<string>();
  
  if (data) {
    data.forEach(entry => {
      const entryCameraId = entry.entry.camera._id;
      const exitCameraId = entry.exit.camera._id;
      
      cameraIdsSet.add(entryCameraId);
      cameraIdsSet.add(exitCameraId);
    });
  }

  return Array.from(cameraIdsSet);
}


