import React, { SVGProps } from 'react';

interface DeviceOfflineIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const DeviceOfflineIcon = ({
  height = '111',
  width = '168',
  color = 'currentColor',
  ...rest
}: DeviceOfflineIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 168 111'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M80.205 0.473633H77.8477V7.53645H80.205V0.473633Z'
        fill='#263238'
      />
      <path
        opacity='0.2'
        d='M79.4484 0.473633H77.8477V7.53645H79.4484V0.473633Z'
        fill='white'
      />
      <path
        d='M144.264 0.473633H141.906V7.53645H144.264V0.473633Z'
        fill='#263238'
      />
      <path
        opacity='0.2'
        d='M143.507 0.473633H141.906V7.53645H143.507V0.473633Z'
        fill='white'
      />
      <path
        d='M67.6855 5.55273V108.994H167.446V5.55273H67.6855ZM149.376 72.0105H73.5951V30.5286H149.376V72.0105Z'
        fill='#263238'
      />
      <path
        opacity='0.7'
        d='M67.6855 5.55273V108.994H167.446V5.55273H67.6855ZM149.376 72.0105H73.5951V30.5286H149.376V72.0105Z'
        fill='white'
      />
      <path
        d='M149.496 29.2461H70.2461V73.399H152.647V29.2461H149.496ZM75.6851 70.6449V32.0002H147.19V70.6449H75.6851Z'
        fill='#263238'
      />
      <path
        opacity='0.2'
        d='M70.2461 29.2461V73.399H149.496V29.2461H70.2461ZM147.208 70.6449H72.5342V32.0002H147.208V70.6449Z'
        fill='white'
      />
      <path
        d='M167.446 75.1377H66.2832V108.999H167.446V75.1377Z'
        fill='#263238'
      />
      <path
        opacity='0.5'
        d='M167.446 75.1377H66.2832V108.999H167.446V75.1377Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M77.8024 77.625H71.1133V80.7251H77.8024V77.625Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M85.3356 77.5879H78.6465V80.688H85.3356V77.5879Z'
        fill='black'
      />
      <path
        opacity='0.3'
        d='M81.9899 81.7627H75.3008V84.8628H81.9899V81.7627Z'
        fill='black'
      />
      <path
        opacity='0.2'
        d='M93.113 86.584H86.4238V89.6841H93.113V86.584Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M100.638 86.5469H93.9531V89.6469H100.638V86.5469Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M97.2966 90.7217H90.6074V93.8217H97.2966V90.7217Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M140.131 88.4287H133.441V91.5288H140.131V88.4287Z'
        fill='white'
      />
      <path
        opacity='0.1'
        d='M132.597 88.4658H125.908V91.5659H132.597V88.4658Z'
        fill='black'
      />
      <path
        opacity='0.2'
        d='M135.936 84.291H129.252V87.3911H135.936V84.291Z'
        fill='black'
      />
      <path
        opacity='0.2'
        d='M83.5915 102.218H76.9023V105.318H83.5915V102.218Z'
        fill='black'
      />
      <path
        opacity='0.2'
        d='M76.0537 102.255H69.3691V105.355H76.0537V102.255Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M79.402 98.0801H72.7129V101.18H79.402V98.0801Z'
        fill='white'
      />
      <path
        opacity='0.1'
        d='M111.201 77.8418H104.512V80.9419H111.201V77.8418Z'
        fill='black'
      />
      <path
        opacity='0.3'
        d='M118.726 77.8047H112.041V80.9048H118.726V77.8047Z'
        fill='black'
      />
      <path
        opacity='0.2'
        d='M115.39 81.9795H108.701V85.0796H115.39V81.9795Z'
        fill='black'
      />
      <path
        opacity='0.1'
        d='M112.871 95.3857H106.182V98.4858H112.871V95.3857Z'
        fill='black'
      />
      <path
        opacity='0.2'
        d='M109.522 99.5605H102.838V102.661H109.522V99.5605Z'
        fill='black'
      />
      <path
        opacity='0.2'
        d='M91.6279 101.854H84.9434V104.954H91.6279V101.854Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M153.343 99.7637H146.654V102.864H153.343V99.7637Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M134.379 98.582H127.689V101.682H134.379V98.582Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M149.999 103.943H143.314V107.043H149.999V103.943Z'
        fill='black'
      />
      <path
        opacity='0.2'
        d='M151.019 78.3633H144.334V81.4634H151.019V78.3633Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M155.211 82.501H148.521V85.601H155.211V82.501Z'
        fill='black'
      />
      <path
        opacity='0.1'
        d='M167.445 5.55273H158.422V110.526H167.445V5.55273Z'
        fill='black'
      />
      <path
        d='M158.422 5.24316H167.446V22.1874H144.135C144.135 22.1874 142.848 5.86595 158.422 5.24316Z'
        fill='#407BFF'
      />
      <path
        opacity='0.2'
        d='M158.422 5.24316H167.446V22.1874H144.135C144.135 22.1874 142.848 5.86595 158.422 5.24316Z'
        fill='black'
      />
      <path
        d='M74.5219 5.24316H69.2767C67.5898 5.24316 65.9193 5.57551 64.3608 6.22123C62.8023 6.86695 61.3863 7.81338 60.1936 9.00647C59.001 10.1996 58.0551 11.6159 57.4099 13.1746C56.7647 14.7334 56.433 16.4039 56.4336 18.0909V22.2428C56.4336 22.9377 56.7097 23.6042 57.2011 24.0956C57.6925 24.587 58.3589 24.8631 59.0539 24.8631C59.7488 24.8631 60.4153 24.587 60.9067 24.0956C61.3981 23.6042 61.6742 22.9377 61.6742 22.2428V18.0909C61.6742 14.6835 63.0278 11.4156 65.4372 9.00618C67.8466 6.59676 71.1145 5.24316 74.5219 5.24316Z'
        fill='#407BFF'
      />
      <path
        d='M85.0077 5.24317H79.7579C76.3505 5.24317 73.0826 6.59677 70.6732 9.00619C68.2638 11.4156 66.9102 14.6835 66.9102 18.0909V22.2428C66.9102 22.9377 67.1862 23.6042 67.6776 24.0956C68.169 24.587 68.8355 24.8631 69.5305 24.8631C69.8749 24.8637 70.2162 24.7964 70.5346 24.665C70.8531 24.5336 71.1425 24.3406 71.3863 24.0973C71.6301 23.8539 71.8235 23.5648 71.9555 23.2466C72.0874 22.9284 72.1554 22.5873 72.1554 22.2428V18.0909C72.1548 16.4032 72.4868 14.7319 73.1325 13.1725C73.7783 11.6132 74.725 10.1964 75.9187 9.0032C77.1123 7.81 78.5294 6.86375 80.089 6.21858C81.6485 5.57341 83.32 5.24195 85.0077 5.24317Z'
        fill='#407BFF'
      />
      <path
        d='M95.4992 5.24316H90.254C86.8466 5.24316 83.5787 6.59676 81.1693 9.00618C78.7599 11.4156 77.4063 14.6835 77.4063 18.0909V22.2428C77.4062 22.5873 77.4742 22.9284 77.6061 23.2466C77.7381 23.5648 77.9315 23.8539 78.1754 24.0973C78.4192 24.3406 78.7086 24.5336 79.027 24.665C79.3454 24.7964 79.6867 24.8637 80.0312 24.8631C80.7261 24.8631 81.3926 24.587 81.884 24.0956C82.3754 23.6042 82.6515 22.9377 82.6515 22.2428V18.0909C82.6515 14.6835 84.0051 11.4156 86.4145 9.00618C88.8239 6.59676 92.0918 5.24316 95.4992 5.24316Z'
        fill='#407BFF'
      />
      <path
        d='M105.983 5.24316H100.738C99.0507 5.24316 97.3803 5.57551 95.8217 6.22123C94.2632 6.86695 92.8472 7.81338 91.6546 9.00647C90.4619 10.1996 89.516 11.6159 88.8708 13.1746C88.2257 14.7334 87.8939 16.4039 87.8945 18.0909V22.2428C87.8945 22.9377 88.1706 23.6042 88.662 24.0956C89.1534 24.587 89.8199 24.8631 90.5148 24.8631C90.8593 24.8637 91.2005 24.7964 91.519 24.665C91.8374 24.5336 92.1268 24.3406 92.3706 24.0973C92.6144 23.8539 92.8079 23.5648 92.9398 23.2466C93.0718 22.9284 93.1397 22.5873 93.1397 22.2428V18.0909C93.1391 16.4039 93.4709 14.7334 94.116 13.1746C94.7612 11.6159 95.7071 10.1996 96.8998 9.00647C98.0924 7.81338 99.5085 6.86695 101.067 6.22123C102.625 5.57551 104.296 5.24316 105.983 5.24316Z'
        fill='#407BFF'
      />
      <path
        d='M116.469 5.24316H111.229C107.821 5.24316 104.553 6.59676 102.144 9.00618C99.7345 11.4156 98.3809 14.6835 98.3809 18.0909V22.2428C98.3809 22.5873 98.4488 22.9284 98.5808 23.2466C98.7127 23.5648 98.9062 23.8539 99.15 24.0973C99.3938 24.3406 99.6832 24.5336 100.002 24.665C100.32 24.7964 100.661 24.8637 101.006 24.8631C101.701 24.8631 102.367 24.587 102.859 24.0956C103.35 23.6042 103.626 22.9377 103.626 22.2428V18.0909C103.626 14.6843 104.979 11.4171 107.387 9.00781C109.796 6.59852 113.063 5.24439 116.469 5.24316Z'
        fill='#407BFF'
      />
      <path
        d='M126.96 5.24316H121.738C120.051 5.24316 118.38 5.57551 116.822 6.22123C115.263 6.86695 113.847 7.81338 112.655 9.00647C111.462 10.1996 110.516 11.6159 109.871 13.1746C109.226 14.7334 108.894 16.4039 108.895 18.0909V22.2428C108.895 22.9377 109.171 23.6042 109.662 24.0956C110.153 24.587 110.82 24.8631 111.515 24.8631C112.21 24.8631 112.876 24.587 113.368 24.0956C113.859 23.6042 114.135 22.9377 114.135 22.2428V18.0909C114.135 14.6875 115.486 11.4231 117.89 9.01433C120.294 6.60557 123.556 5.24927 126.96 5.24316Z'
        fill='#407BFF'
      />
      <path
        d='M137.446 5.24316H132.201C128.794 5.24439 125.527 6.59852 123.119 9.00781C120.71 11.4171 119.357 14.6843 119.357 18.0909V22.2428C119.357 22.9377 119.633 23.6042 120.125 24.0956C120.616 24.587 121.283 24.8631 121.978 24.8631C122.322 24.8637 122.663 24.7964 122.982 24.665C123.3 24.5336 123.59 24.3406 123.834 24.0973C124.077 23.8539 124.271 23.5648 124.403 23.2466C124.535 22.9284 124.603 22.5873 124.603 22.2428V18.0909C124.602 16.4039 124.934 14.7334 125.579 13.1746C126.224 11.6159 127.17 10.1996 128.363 9.00647C129.555 7.81338 130.971 6.86695 132.53 6.22123C134.088 5.57551 135.759 5.24316 137.446 5.24316Z'
        fill='#407BFF'
      />
      <path
        d='M147.937 5.24316H142.692C139.284 5.24316 136.016 6.59676 133.607 9.00618C131.197 11.4156 129.844 14.6835 129.844 18.0909V22.2428C129.844 22.5873 129.912 22.9284 130.044 23.2466C130.176 23.5648 130.369 23.8539 130.613 24.0973C130.857 24.3406 131.146 24.5336 131.464 24.665C131.783 24.7964 132.124 24.8637 132.469 24.8631C132.813 24.8631 133.153 24.7953 133.471 24.6636C133.789 24.532 134.078 24.3389 134.321 24.0956C134.565 23.8523 134.758 23.5634 134.889 23.2455C135.021 22.9276 135.089 22.5869 135.089 22.2428V18.0909C135.089 14.6835 136.443 11.4156 138.852 9.00618C141.261 6.59676 144.529 5.24316 147.937 5.24316Z'
        fill='#407BFF'
      />
      <path
        d='M79.7576 5.24316H74.5216C71.1141 5.24316 67.8463 6.59676 65.4368 9.00618C63.0274 11.4156 61.6738 14.6835 61.6738 18.0909V22.2428C61.6738 22.5873 61.7418 22.9284 61.8737 23.2466C62.0057 23.5648 62.1991 23.8539 62.4429 24.0973C62.6867 24.3406 62.9761 24.5336 63.2946 24.665C63.613 24.7964 63.9542 24.8637 64.2987 24.8631C64.9937 24.8631 65.6602 24.587 66.1516 24.0956C66.643 23.6042 66.919 22.9377 66.919 22.2428V18.0909C66.919 14.6851 68.2714 11.4186 70.6788 9.00944C73.0862 6.60029 76.3517 5.24561 79.7576 5.24316Z'
        fill='#407BFF'
      />
      <path
        d='M90.2524 5.24316H85.0072C83.3202 5.24316 81.6498 5.57551 80.0913 6.22123C78.5328 6.86695 77.1168 7.81338 75.9241 9.00647C74.7314 10.1996 73.7855 11.6159 73.1404 13.1746C72.4952 14.7334 72.1635 16.4039 72.1641 18.0909V22.2428C72.1641 22.9377 72.4401 23.6042 72.9315 24.0956C73.4229 24.587 74.0894 24.8631 74.7844 24.8631C75.4793 24.8631 76.1458 24.587 76.6372 24.0956C77.1286 23.6042 77.4047 22.9377 77.4047 22.2428V18.0909C77.4047 14.6835 78.7583 11.4156 81.1677 9.00618C83.5771 6.59676 86.845 5.24316 90.2524 5.24316Z'
        fill='#407BFF'
      />
      <path
        d='M100.739 5.24316H95.4981C92.0907 5.24316 88.8228 6.59676 86.4134 9.00618C84.004 11.4156 82.6504 14.6835 82.6504 18.0909V22.2428C82.6516 22.5881 82.721 22.9297 82.8547 23.2481C82.9884 23.5664 83.1836 23.8552 83.4293 24.0979C83.6749 24.3405 83.9661 24.5322 84.2861 24.6619C84.606 24.7917 84.9485 24.8569 85.2938 24.8539C85.6382 24.8545 85.9795 24.7871 86.2979 24.6557C86.6164 24.5243 86.9058 24.3314 87.1496 24.088C87.3934 23.8447 87.5868 23.5556 87.7188 23.2374C87.8507 22.9192 87.9187 22.5781 87.9187 22.2336V18.0817C87.9199 14.6803 89.2704 11.4182 91.6738 9.01134C94.0772 6.60445 97.3373 5.24927 100.739 5.24316Z'
        fill='#407BFF'
      />
      <path
        d='M111.23 5.24316H105.984C104.297 5.24256 102.626 5.57446 101.067 6.21991C99.5081 6.86535 98.0915 7.81169 96.8984 9.00483C95.7052 10.198 94.7589 11.6145 94.1135 13.1736C93.468 14.7326 93.1361 16.4036 93.1367 18.0909V22.2428C93.1367 22.9377 93.4128 23.6042 93.9042 24.0956C94.3956 24.587 95.0621 24.8631 95.757 24.8631C96.452 24.8631 97.1185 24.587 97.6099 24.0956C98.1013 23.6042 98.3773 22.9377 98.3773 22.2428V18.0909C98.3773 16.4033 98.7098 14.7323 99.3557 13.1732C100.002 11.6142 100.948 10.1976 102.142 9.00455C103.335 7.81147 104.752 6.86521 106.312 6.21982C107.871 5.57443 109.542 5.24256 111.23 5.24316Z'
        fill='#407BFF'
      />
      <path
        d='M121.738 5.24318H116.47C113.063 5.24441 109.797 6.59854 107.388 9.00783C104.98 11.4171 103.627 14.6843 103.627 18.0909V22.2428C103.627 22.9378 103.903 23.6042 104.394 24.0956C104.886 24.587 105.552 24.8631 106.247 24.8631C106.592 24.8637 106.933 24.7964 107.251 24.665C107.57 24.5336 107.859 24.3407 108.103 24.0973C108.347 23.8539 108.54 23.5648 108.672 23.2466C108.804 22.9284 108.872 22.5873 108.872 22.2428V18.0909C108.872 16.402 109.204 14.7296 109.851 13.1693C110.497 11.6091 111.445 10.1917 112.64 8.99831C113.835 7.80493 115.254 6.85898 116.815 6.21462C118.377 5.57026 120.049 5.24015 121.738 5.24318Z'
        fill='#407BFF'
      />
      <path
        d='M132.2 5.24316H126.959C125.272 5.24316 123.601 5.57548 122.042 6.22114C120.484 6.8668 119.067 7.81316 117.874 9.00618C116.681 10.1992 115.735 11.6155 115.089 13.1743C114.444 14.7331 114.111 16.4037 114.111 18.0909V22.2428C114.111 22.5873 114.179 22.9284 114.311 23.2466C114.443 23.5648 114.637 23.8539 114.88 24.0973C115.124 24.3406 115.414 24.5336 115.732 24.665C116.051 24.7964 116.392 24.8637 116.736 24.8631C117.431 24.8631 118.098 24.587 118.589 24.0956C119.08 23.6042 119.357 22.9377 119.357 22.2428V18.0909C119.357 14.6843 120.709 11.4171 123.118 9.00781C125.526 6.59852 128.793 5.24439 132.2 5.24316Z'
        fill='#407BFF'
      />
      <path
        d='M142.692 5.24316H137.447C135.76 5.24316 134.089 5.57551 132.531 6.22123C130.972 6.86695 129.556 7.81338 128.364 9.00647C127.171 10.1996 126.225 11.6159 125.58 13.1746C124.935 14.7334 124.603 16.4039 124.604 18.0909V22.2428C124.604 22.9377 124.88 23.6042 125.371 24.0956C125.862 24.587 126.529 24.8631 127.224 24.8631C127.568 24.8631 127.909 24.7953 128.227 24.6636C128.544 24.532 128.833 24.3389 129.077 24.0956C129.32 23.8523 129.513 23.5634 129.645 23.2455C129.776 22.9276 129.844 22.5869 129.844 22.2428V18.0909C129.844 14.6835 131.198 11.4156 133.607 9.00618C136.017 6.59676 139.284 5.24316 142.692 5.24316Z'
        fill='#407BFF'
      />
      <path
        d='M153.176 5.24316H147.936C144.528 5.24316 141.26 6.59676 138.851 9.00618C136.441 11.4156 135.088 14.6835 135.088 18.0909V22.2428C135.088 22.9377 135.364 23.6042 135.855 24.0956C136.347 24.587 137.013 24.8631 137.708 24.8631C138.053 24.8637 138.394 24.7964 138.712 24.665C139.031 24.5336 139.32 24.3406 139.564 24.0973C139.808 23.8539 140.001 23.5648 140.133 23.2466C140.265 22.9284 140.333 22.5873 140.333 22.2428V18.0909C140.332 16.4039 140.664 14.7334 141.309 13.1746C141.955 11.6159 142.9 10.1996 144.093 9.00647C145.286 7.81338 146.702 6.86695 148.26 6.22123C149.819 5.57551 151.489 5.24316 153.176 5.24316Z'
        fill='#407BFF'
      />
      <path
        d='M158.422 5.24316H153.177C151.49 5.24316 149.82 5.57551 148.261 6.22123C146.703 6.86695 145.287 7.81338 144.094 9.00647C142.901 10.1996 141.955 11.6159 141.31 13.1746C140.665 14.7334 140.333 16.4039 140.334 18.0909V22.2428C140.336 22.9369 140.614 23.6018 141.106 24.0913C141.598 24.5808 142.265 24.8551 142.959 24.8539C143.303 24.8545 143.645 24.7871 143.963 24.6557C144.282 24.5243 144.571 24.3314 144.815 24.088C145.059 23.8447 145.252 23.5556 145.384 23.2374C145.516 22.9192 145.584 22.5781 145.584 22.2336V18.0817C145.585 14.6771 146.938 11.4123 149.345 9.00483C151.753 6.59741 155.018 5.24439 158.422 5.24316Z'
        fill='#407BFF'
      />
      <g opacity='0.7'>
        <path
          d='M79.7576 5.24414H74.5216C71.1141 5.24414 67.8463 6.59774 65.4368 9.00716C63.0274 11.4166 61.6738 14.6845 61.6738 18.0919V22.2438C61.6738 22.5883 61.7418 22.9294 61.8737 23.2476C62.0057 23.5658 62.1991 23.8549 62.4429 24.0982C62.6867 24.3416 62.9761 24.5345 63.2946 24.6659C63.613 24.7974 63.9542 24.8647 64.2987 24.8641C64.9937 24.8641 65.6602 24.588 66.1516 24.0966C66.643 23.6052 66.919 22.9387 66.919 22.2438V18.0919C66.919 14.6861 68.2714 11.4196 70.6788 9.01042C73.0862 6.60126 76.3517 5.24659 79.7576 5.24414Z'
          fill='white'
        />
        <path
          d='M90.2524 5.24414H85.0072C83.3202 5.24414 81.6498 5.57649 80.0913 6.22221C78.5328 6.86792 77.1168 7.81436 75.9241 9.00744C74.7314 10.2005 73.7855 11.6169 73.1404 13.1756C72.4952 14.7344 72.1635 16.4049 72.1641 18.0919V22.2438C72.1641 22.9387 72.4401 23.6052 72.9315 24.0966C73.4229 24.588 74.0894 24.8641 74.7844 24.8641C75.4793 24.8641 76.1458 24.588 76.6372 24.0966C77.1286 23.6052 77.4047 22.9387 77.4047 22.2438V18.0919C77.4047 14.6845 78.7583 11.4166 81.1677 9.00716C83.5771 6.59774 86.845 5.24414 90.2524 5.24414Z'
          fill='white'
        />
        <path
          d='M100.739 5.24414H95.4981C92.0907 5.24414 88.8228 6.59774 86.4134 9.00716C84.004 11.4166 82.6504 14.6845 82.6504 18.0919V22.2438C82.6516 22.589 82.721 22.9307 82.8547 23.249C82.9884 23.5674 83.1836 23.8562 83.4293 24.0988C83.6749 24.3415 83.9661 24.5332 84.2861 24.6629C84.606 24.7927 84.9485 24.8579 85.2938 24.8548C85.6382 24.8555 85.9795 24.7881 86.2979 24.6567C86.6164 24.5253 86.9058 24.3324 87.1496 24.089C87.3934 23.8456 87.5868 23.5566 87.7188 23.2384C87.8507 22.9201 87.9187 22.579 87.9187 22.2346V18.0827C87.9199 14.6813 89.2704 11.4192 91.6738 9.01232C94.0772 6.60542 97.3373 5.25025 100.739 5.24414Z'
          fill='white'
        />
        <path
          d='M111.228 5.24414H105.983C104.295 5.24354 102.624 5.57544 101.065 6.22088C99.5061 6.86633 98.0896 7.81266 96.8964 9.00581C95.7033 10.199 94.757 11.6155 94.1115 13.1746C93.4661 14.7336 93.1342 16.4045 93.1348 18.0919V22.2438C93.1348 22.9387 93.4108 23.6052 93.9022 24.0966C94.3936 24.588 95.0601 24.8641 95.7551 24.8641C96.45 24.8641 97.1165 24.588 97.6079 24.0966C98.0993 23.6052 98.3754 22.9387 98.3754 22.2438V18.0919C98.3754 16.4043 98.7078 14.7333 99.3538 13.1742C99.9997 11.6151 100.946 10.1986 102.14 9.00553C103.334 7.81244 104.75 6.86618 106.31 6.22079C107.869 5.5754 109.54 5.24354 111.228 5.24414Z'
          fill='white'
        />
        <path
          d='M121.738 5.24416H116.47C113.063 5.24538 109.797 6.59952 107.388 9.00881C104.98 11.4181 103.627 14.6853 103.627 18.0919V22.2438C103.627 22.9387 103.903 23.6052 104.394 24.0966C104.886 24.588 105.552 24.8641 106.247 24.8641C106.592 24.8647 106.933 24.7974 107.251 24.666C107.57 24.5346 107.859 24.3416 108.103 24.0983C108.347 23.8549 108.54 23.5658 108.672 23.2476C108.804 22.9294 108.872 22.5883 108.872 22.2438V18.0919C108.872 16.403 109.204 14.7305 109.851 13.1703C110.497 11.6101 111.445 10.1927 112.64 8.99929C113.835 7.8059 115.254 6.85996 116.815 6.2156C118.377 5.57124 120.049 5.24113 121.738 5.24416Z'
          fill='white'
        />
        <path
          d='M132.2 5.24414H126.959C125.272 5.24414 123.601 5.57646 122.042 6.22212C120.484 6.86778 119.067 7.81414 117.874 9.00716C116.681 10.2002 115.735 11.6165 115.089 13.1753C114.444 14.734 114.111 16.4047 114.111 18.0919V22.2438C114.111 22.5883 114.179 22.9294 114.311 23.2476C114.443 23.5658 114.637 23.8549 114.88 24.0982C115.124 24.3416 115.414 24.5345 115.732 24.6659C116.051 24.7974 116.392 24.8647 116.736 24.8641C117.431 24.8641 118.098 24.588 118.589 24.0966C119.08 23.6052 119.357 22.9387 119.357 22.2438V18.0919C119.357 14.6853 120.709 11.4181 123.118 9.00879C125.526 6.5995 128.793 5.24536 132.2 5.24414Z'
          fill='white'
        />
        <path
          d='M142.692 5.24414H137.447C135.76 5.24414 134.089 5.57649 132.531 6.22221C130.972 6.86792 129.556 7.81436 128.364 9.00744C127.171 10.2005 126.225 11.6169 125.58 13.1756C124.935 14.7344 124.603 16.4049 124.604 18.0919V22.2438C124.604 22.9387 124.88 23.6052 125.371 24.0966C125.862 24.588 126.529 24.8641 127.224 24.8641C127.568 24.8641 127.909 24.7963 128.227 24.6646C128.544 24.5329 128.833 24.3399 129.077 24.0966C129.32 23.8533 129.513 23.5644 129.645 23.2465C129.776 22.9286 129.844 22.5879 129.844 22.2438V18.0919C129.844 14.6845 131.198 11.4166 133.607 9.00716C136.017 6.59774 139.284 5.24414 142.692 5.24414Z'
          fill='white'
        />
        <path
          d='M153.176 5.24414H147.936C144.528 5.24414 141.26 6.59774 138.851 9.00716C136.441 11.4166 135.088 14.6845 135.088 18.0919V22.2438C135.088 22.9387 135.364 23.6052 135.855 24.0966C136.347 24.588 137.013 24.8641 137.708 24.8641C138.053 24.8647 138.394 24.7974 138.712 24.6659C139.031 24.5345 139.32 24.3416 139.564 24.0982C139.808 23.8549 140.001 23.5658 140.133 23.2476C140.265 22.9294 140.333 22.5883 140.333 22.2438V18.0919C140.332 16.4049 140.664 14.7344 141.309 13.1756C141.955 11.6169 142.9 10.2005 144.093 9.00744C145.286 7.81436 146.702 6.86792 148.26 6.22221C149.819 5.57649 151.489 5.24414 153.176 5.24414Z'
          fill='white'
        />
      </g>
      <path
        d='M84.6803 84.8252H91.7939C93.6622 84.8252 95.1753 90.1903 95.1753 96.8195C95.1753 103.449 93.6622 108.814 91.7939 108.814H84.6803C82.835 108.814 81.2988 103.449 81.2988 96.8195C81.2988 90.1903 82.8166 84.8252 84.6803 84.8252Z'
        fill='#263238'
      />
      <path
        opacity='0.2'
        d='M84.1541 85.665C85.7133 85.665 86.9773 90.6519 86.9773 96.8013C86.9773 102.951 85.7133 107.938 84.1541 107.938C82.5948 107.938 81.3262 102.951 81.3262 96.8197C81.3262 90.6888 82.5902 85.665 84.1541 85.665Z'
        fill='white'
      />
      <path
        opacity='0.6'
        d='M83.9098 90.7861C84.7494 90.7861 85.4321 93.4802 85.4321 96.7833C85.4321 100.086 84.7494 102.78 83.9098 102.78C83.0702 102.78 82.3828 100.086 82.3828 96.7833C82.3828 93.4802 83.0656 90.7861 83.9098 90.7861Z'
        fill='white'
      />
      <path
        opacity='0.6'
        d='M84.1553 91.6113C84.8796 91.6113 85.4701 93.9179 85.4701 96.8012C85.4701 99.6844 84.8796 101.991 84.1553 101.991C83.431 101.991 82.8359 99.6844 82.8359 96.8012C82.8359 93.9179 83.4495 91.6113 84.1553 91.6113Z'
        fill='white'
      />
      <path
        d='M84.7788 94.2041C85.1433 94.2041 85.4339 95.3666 85.4339 96.8013C85.4339 98.236 85.1433 99.3986 84.7788 99.3986C84.4144 99.3986 84.1191 98.236 84.1191 96.8013C84.1191 95.3666 84.4144 94.2041 84.7788 94.2041Z'
        fill='#263238'
      />
      <path
        opacity='0.2'
        d='M84.4552 95.5049C84.6351 95.5049 84.7827 96.0861 84.7827 96.8012C84.7827 97.5162 84.6351 98.0975 84.4552 98.0975C84.2753 98.0975 84.123 97.5162 84.123 96.8012C84.123 96.0861 84.2707 95.5049 84.4552 95.5049Z'
        fill='white'
      />
      <path
        d='M63.7174 84.8252H70.831C72.6762 84.8252 74.2078 90.1903 74.2078 96.8195C74.2078 103.449 72.6947 108.814 70.831 108.814H63.7174C61.8721 108.814 60.3359 103.449 60.3359 96.8195C60.3359 90.1903 61.8491 84.8252 63.7174 84.8252Z'
        fill='#263238'
      />
      <path
        opacity='0.2'
        d='M63.1876 85.665C64.7469 85.665 66.0109 90.6519 66.0109 96.8013C66.0109 102.951 64.7469 107.938 63.1876 107.938C61.6284 107.938 60.3828 102.951 60.3828 96.8197C60.3828 90.6888 61.6284 85.665 63.1876 85.665Z'
        fill='white'
      />
      <path
        opacity='0.6'
        d='M62.943 90.7861C63.7872 90.7861 64.47 93.4802 64.47 96.7833C64.47 100.086 63.7872 102.78 62.943 102.78C62.0988 102.78 61.416 100.086 61.416 96.7833C61.416 93.4802 62.0988 90.7861 62.943 90.7861Z'
        fill='white'
      />
      <path
        opacity='0.6'
        d='M63.1878 91.6113C63.9167 91.6113 64.5026 93.9179 64.5026 96.8012C64.5026 99.6844 63.9167 101.991 63.1878 101.991C62.4589 101.991 61.873 99.6844 61.873 96.8012C61.873 93.9179 62.4589 91.6113 63.1878 91.6113Z'
        fill='white'
      />
      <path
        d='M63.8101 94.2041C64.1745 94.2041 64.4698 95.3666 64.4698 96.8013C64.4698 98.236 64.1745 99.3986 63.8101 99.3986C63.4456 99.3986 63.1504 98.236 63.1504 96.8198C63.1504 95.4035 63.4456 94.2041 63.8101 94.2041Z'
        fill='#263238'
      />
      <path
        opacity='0.2'
        d='M63.4872 95.5049C63.6717 95.5049 63.8193 96.0861 63.8193 96.8012C63.8193 97.5162 63.6717 98.0975 63.4872 98.0975C63.3026 98.0975 63.1504 97.5162 63.1504 96.8196C63.1504 96.1231 63.3072 95.5049 63.4872 95.5049Z'
        fill='white'
      />
      <path
        d='M30.7057 84.8252H37.8146C39.6599 84.8252 41.1961 90.1903 41.1961 96.8195C41.1961 103.449 39.683 108.814 37.8146 108.814H30.7057C28.8604 108.814 27.3242 103.449 27.3242 96.8195C27.3242 90.1903 28.8373 84.8252 30.7057 84.8252Z'
        fill='#263238'
      />
      <path
        opacity='0.2'
        d='M30.1748 85.665C31.7341 85.665 32.9981 90.6519 32.9981 96.8013C32.9981 102.951 31.7341 107.938 30.1748 107.938C28.6156 107.938 27.3516 102.951 27.3516 96.8197C27.3516 90.6888 28.6156 85.665 30.1748 85.665Z'
        fill='white'
      />
      <path
        opacity='0.6'
        d='M29.9352 90.7861C30.7794 90.7861 31.4621 93.4802 31.4621 96.7833C31.4621 100.086 30.7794 102.78 29.9352 102.78C29.091 102.78 28.4082 100.086 28.4082 96.7833C28.4082 93.4802 29.0863 90.7861 29.9352 90.7861Z'
        fill='white'
      />
      <path
        opacity='0.6'
        d='M30.1748 91.6113C30.9037 91.6113 31.4896 93.9179 31.4896 96.8012C31.4896 99.6844 30.9037 101.991 30.1748 101.991C29.446 101.991 28.8555 99.6844 28.8555 96.8012C28.8555 93.9179 29.446 91.6113 30.1748 91.6113Z'
        fill='white'
      />
      <path
        d='M30.7984 94.2041C31.1628 94.2041 31.458 95.3666 31.458 96.8013C31.458 98.236 31.1628 99.3986 30.7984 99.3986C30.4339 99.3986 30.1387 98.236 30.1387 96.8013C30.1387 95.3666 30.4339 94.2041 30.7984 94.2041Z'
        fill='#263238'
      />
      <path
        opacity='0.2'
        d='M30.476 95.5049C30.6559 95.5049 30.8035 96.0861 30.8035 96.8012C30.8035 97.5162 30.6559 98.0975 30.476 98.0975C30.2961 98.0975 30.1484 97.5162 30.1484 96.8012C30.1484 96.0861 30.2961 95.5049 30.476 95.5049Z'
        fill='white'
      />
      <path
        d='M9.7389 84.8252H16.8524C18.6977 84.8252 20.2339 90.1903 20.2339 96.8195C20.2339 103.449 18.7208 108.814 16.8524 108.814H9.7389C7.89362 108.814 6.35742 103.449 6.35742 96.8195C6.35742 90.1903 7.87055 84.8252 9.7389 84.8252Z'
        fill='#263238'
      />
      <path
        opacity='0.2'
        d='M9.20841 85.665C10.7677 85.665 12.0363 90.6519 12.0363 96.8013C12.0363 102.951 10.7677 107.938 9.20841 107.938C7.64915 107.938 6.4082 102.951 6.4082 96.8197C6.4082 90.6888 7.64915 85.665 9.20841 85.665Z'
        fill='white'
      />
      <path
        opacity='0.6'
        d='M8.96447 90.7861C9.80868 90.7861 10.4914 93.4802 10.4914 96.7833C10.4914 100.086 9.80868 102.78 8.96447 102.78C8.12025 102.78 7.4375 100.086 7.4375 96.7833C7.4375 93.4802 8.12487 90.7861 8.96447 90.7861Z'
        fill='white'
      />
      <path
        opacity='0.6'
        d='M9.20929 91.6113C9.93818 91.6113 10.5287 93.9179 10.5287 96.8012C10.5287 99.6844 9.93818 101.991 9.20929 101.991C8.48041 101.991 7.89453 99.6844 7.89453 96.8012C7.89453 93.9179 8.48502 91.6113 9.20929 91.6113Z'
        fill='white'
      />
      <path
        d='M9.83085 94.2041C10.1953 94.2041 10.4905 95.3666 10.4905 96.8013C10.4905 98.236 10.1953 99.3986 9.83085 99.3986C9.46641 99.3986 9.17578 98.236 9.17578 96.8198C9.17578 95.4035 9.47103 94.2041 9.83085 94.2041Z'
        fill='#263238'
      />
      <path
        opacity='0.2'
        d='M9.50793 95.5049C9.69246 95.5049 9.84008 96.0861 9.84008 96.8012C9.84008 97.5162 9.69246 98.0975 9.50793 98.0975C9.3234 98.0975 9.17578 97.5162 9.17578 96.8196C9.17578 96.1231 9.32802 95.5049 9.50793 95.5049Z'
        fill='white'
      />
      <path
        d='M102.141 89.0835C102.141 90.2045 101.998 91.9852 101.823 93.7613C101.693 95.0576 101.55 96.3447 101.431 97.3735C101.361 97.9594 101.079 98.4994 100.638 98.8907C100.196 99.282 99.6263 99.4973 99.0363 99.4955H36.8965C36.2875 99.4935 35.7018 99.2613 35.2569 98.8455C34.8119 98.4297 34.5407 97.861 34.4976 97.2535C34.0594 91.2794 32.4494 84.7979 30.1059 84.7979C27.4118 84.7979 26.8766 93.0094 26.8766 99.4771H15.2744C14.7711 99.4774 14.2873 99.2823 13.925 98.9329C13.5627 98.5835 13.3502 98.1071 13.3323 97.6041C13.0232 88.3316 10.6935 83.7138 8.58531 85.0285C6.03421 86.6247 6.34791 95.2837 6.03421 98.5268C6.02097 98.6573 5.97358 98.7821 5.89682 98.8885C5.82006 98.9949 5.71662 99.0793 5.59692 99.133C5.47722 99.1868 5.34547 99.2081 5.21492 99.1948C5.08437 99.1815 4.95962 99.1341 4.85323 99.0573C4.49469 98.793 4.15835 98.4998 3.84755 98.1808C3.15557 97.4473 1.82236 91.7915 1.07963 88.6914C0.839744 87.7088 0.655216 86.9845 0.567565 86.7539C0.387651 86.2649 2.41285 65.8099 7.91178 62.4884C7.91178 62.4884 11.344 48.7227 16.5246 44.7092C16.7417 44.5404 16.9698 44.3863 17.2074 44.2479C17.5171 44.0556 17.8485 43.9008 18.1946 43.7865C30.1889 40.0314 64.0821 40.2021 71.8369 45.1244C72.003 45.232 72.1567 45.3381 72.2982 45.4427C73.1009 46.0608 73.8897 46.7298 74.6555 47.431C79.6424 52.0119 83.7804 58.0413 85.8887 61.4043L85.9256 61.4597C86.6637 62.6315 87.1481 63.4664 87.3095 63.7663L87.3695 63.8678V63.9001C100.517 75.1378 100.12 78.7453 101.334 87.1321C101.838 87.6581 102.127 88.3548 102.141 89.0835Z'
        fill='#407BFF'
      />
      <path
        opacity='0.2'
        d='M100.518 96.4141H82.0051V93.1849C82.0051 92.9067 81.8949 92.6399 81.6987 92.4428C81.5024 92.2457 81.2361 92.1343 80.9579 92.1331H68.7283C68.4502 92.1343 68.1838 92.2457 67.9876 92.4428C67.7913 92.6399 67.6811 92.9067 67.6811 93.1849V96.4141H42.53C42.1089 96.4143 41.7032 96.2559 41.3937 95.9703C41.0842 95.6847 40.8936 95.2931 40.86 94.8733C40.6155 91.8378 40.6708 88.8992 40.6708 88.7516C40.7939 88.3353 40.9707 87.9367 41.1968 87.566C41.1968 87.566 41.3997 79.8066 40.3064 75.521C39.2131 71.2353 35.9331 65.4504 35.9331 65.4504L34.7337 45.2123C27.5002 44.0174 19.6808 44.4557 16.5208 44.714C11.3401 48.7275 7.90791 62.4933 7.90791 62.4933C2.39975 65.8148 0.383788 86.2697 0.563703 86.7587C0.651353 86.9894 0.835882 87.7137 1.07577 88.6963C1.83233 91.7963 3.16555 97.4613 3.84369 98.1856C4.15293 98.5046 4.48773 98.7977 4.84475 99.0621C4.95045 99.1415 5.07543 99.1912 5.20677 99.2062C5.33811 99.2211 5.47106 99.2007 5.5919 99.1471C5.71273 99.0935 5.81707 99.0087 5.89414 98.9013C5.97122 98.7939 6.01824 98.6678 6.03034 98.5362C6.34404 95.307 6.03034 86.6296 8.58144 85.0334C10.6897 83.7186 13.0193 88.3364 13.3284 97.609C13.3464 98.112 13.5589 98.5884 13.9212 98.9378C14.2835 99.2871 14.7673 99.4822 15.2706 99.4819H26.8728C26.8728 93.0234 27.4079 84.8027 30.102 84.8027C32.4501 84.8027 34.0601 91.2612 34.4938 97.2584C34.5404 97.8621 34.8135 98.4258 35.2584 98.8365C35.7034 99.2472 36.2871 99.4745 36.8926 99.4727H99.0324C99.6228 99.4733 100.193 99.2571 100.634 98.865C101.076 98.4729 101.358 97.9324 101.427 97.346C101.547 96.3173 101.69 95.0394 101.819 93.7385L100.518 96.4141Z'
        fill='black'
      />
      <path
        opacity='0.2'
        d='M38.115 68.0377C38.115 68.0377 39.5728 72.5079 50.1601 77.9838C60.7474 83.4597 72.5387 86.6197 84.7591 83.4597C94.3038 80.9962 93.2059 70.4136 93.2059 70.4136C93.2059 70.4136 93.0121 68.9696 87.361 63.9551C87.361 63.9551 50.9167 67.844 37.1094 65.9387L38.115 68.0377Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M71.8248 45.1521C69.8411 45.881 65.8553 46.0747 57.916 46.0747C47.97 46.0747 35.0115 44.4924 30.1769 44.0357C27.0076 43.7358 20.9505 44.0357 17.1953 44.2571C17.505 44.0649 17.8364 43.91 18.1825 43.7958C30.1584 40.0776 64.0746 40.2298 71.8248 45.1521Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M28.1413 83.7323C24.5568 84.4612 24.6998 97.2398 24.6998 97.2398H13.7527C13.4759 89.2082 11.4461 82.8189 8.35526 83.3679C5.26442 83.9168 5.0845 92.8572 5.0845 92.8572C4.05115 92.5481 2.35349 90.463 1.06641 88.7053C1.82297 91.8054 3.15619 97.4704 3.83433 98.1947C4.14357 98.5137 4.47837 98.8068 4.83539 99.0712C4.94109 99.1506 5.06607 99.2003 5.19741 99.2153C5.32875 99.2302 5.4617 99.2098 5.58254 99.1562C5.70337 99.1026 5.80771 99.0177 5.88479 98.9103C5.96186 98.803 6.00888 98.6769 6.02098 98.5453C6.33468 95.3161 6.02098 86.6386 8.57208 85.0425C10.6803 83.7277 13.01 88.3455 13.3191 97.6181C13.337 98.1211 13.5495 98.5974 13.9118 98.9468C14.2741 99.2962 14.7579 99.4913 15.2612 99.491H26.8634C26.8634 93.0325 27.3985 84.8118 30.0927 84.8118C32.4408 84.8118 34.0508 91.2703 34.4844 97.2674C34.531 97.8712 34.8041 98.4349 35.2491 98.8456C35.694 99.2563 36.2778 99.4836 36.8833 99.4818H39.4159L38.1335 98.9836C37.5853 98.7708 37.1037 98.4159 36.738 97.9554C36.3724 97.4949 36.1359 96.9454 36.0529 96.3633C35.0103 88.5946 31.4628 83.0588 28.1413 83.7323Z'
        fill='black'
      />
      <path
        opacity='0.7'
        d='M42.8893 75.4608C44.9883 74.5382 54.2055 83.1003 55.0543 85.6652C55.9031 88.2301 46.1278 87.5105 44.5085 86.6524C42.8893 85.7944 41.1594 76.2266 42.8893 75.4608Z'
        fill='white'
      />
      <path
        opacity='0.7'
        d='M97.4096 75.6312C95.043 74.7086 88.3308 83.0815 88.2293 85.5865C88.1278 88.0914 97.6956 87.381 99.0657 86.5506C100.436 85.7203 99.361 76.3785 97.4096 75.6312Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M42.1699 87.3213C42.1699 87.3213 87.2455 90.3014 99.6827 87.3213C99.6827 87.3213 88.7817 93.2216 42.1699 87.3213Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M21.5392 45.041C21.5392 45.041 18.0839 51.4303 16.7968 63.2954C16.7968 63.2954 15.3344 65.3806 15.0622 66.6585C14.7901 67.9363 13.5814 83.063 14.9469 87.4455L15.482 92.3125L23.2414 93.2351L16.3216 91.6251C16.3216 91.6251 15.399 87.1918 15.3298 86.6659C14.2365 80.5534 15.7911 67.415 15.7911 67.415L17.5349 63.7614C17.5349 63.7614 18.0839 55.7621 21.5392 45.041Z'
        fill='black'
      />
      <path
        d='M34.7245 65.45L34.2632 46.3375C34.2632 46.3375 22.5918 45.3872 14.6848 47.0387C14.6848 47.0387 9.81785 55.1395 8.35547 62.4929C8.35547 62.4929 15.5982 61.6349 17.5034 63.7339L34.7245 65.45Z'
        fill='#263238'
      />
      <path
        d='M87.3833 63.9416C87.3833 63.9416 83.8357 68.5225 71.4262 68.5225C59.0167 68.5225 37.1317 65.9114 37.1317 65.9114L35.3418 46.2822C35.3418 46.2822 61.125 47.8507 74.637 47.4447C79.6239 52.0257 83.7619 58.0551 85.8702 61.4181L85.9071 61.4735L87.291 63.7801L87.351 63.8816L87.3833 63.9416Z'
        fill='#263238'
      />
      <path
        opacity='0.2'
        d='M30.1758 78.5335C30.1758 78.5335 30.1758 69.4086 30.8032 67.7663C31.1639 66.8183 31.7203 65.9569 32.4362 65.2383H34.2815C34.2815 65.2383 31.8227 66.7283 31.5136 68.5229C31.2045 70.3174 30.1758 78.5335 30.1758 78.5335Z'
        fill='black'
      />
      <path
        opacity='0.2'
        d='M25.5215 81.9974C25.5215 81.9974 26.6932 79.0773 31.491 79.4463C36.2887 79.8154 38.9367 86.5599 38.9367 86.5599C38.9367 86.5599 37.3267 88.5389 36.6716 87.321C36.0165 86.1032 31.8185 78.2561 25.5215 81.9974Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M12.568 85.287C12.568 85.287 10.8104 81.3428 8.35614 81.4443C5.90192 81.5457 4.80859 84.6504 4.80859 84.6504C4.80859 84.6504 5.08538 81.7256 6.81533 80.5401C8.54528 79.3545 9.96615 79.336 11.6823 80.6323C13.2969 81.8456 14.0581 85.6653 14.0581 85.6653C13.8017 85.7688 13.5191 85.7878 13.2512 85.7198C12.9832 85.6518 12.744 85.5002 12.568 85.287Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M16.7926 69.5605C16.7926 69.5605 19.4083 70.0219 19.5282 70.7738C19.6481 71.5258 16.4881 71.2028 16.4881 71.2028C16.4628 70.6393 16.5669 70.0775 16.7926 69.5605Z'
        fill='black'
      />
      <path
        opacity='0.2'
        d='M58.7353 87.3218L54.3574 80.9971C54.3574 80.9971 64.0451 85.2873 73.6083 85.2873C83.1715 85.2873 88.2275 82.9115 88.2275 82.9115L85.7871 87.7185C85.7871 87.7185 76.2655 88.9041 58.7353 87.3218Z'
        fill='black'
      />
      <path
        d='M80.3158 93.2217H68.7274C68.4497 93.2217 68.2246 93.4468 68.2246 93.7245V97.595C68.2246 97.8727 68.4497 98.0978 68.7274 98.0978H80.3158C80.5935 98.0978 80.8186 97.8727 80.8186 97.595V93.7245C80.8186 93.4468 80.5935 93.2217 80.3158 93.2217Z'
        fill='white'
      />
      <path
        d='M50.8286 83.3352C50.8286 83.9394 50.6494 84.53 50.3136 85.0323C49.9778 85.5347 49.5006 85.9261 48.9423 86.1571C48.384 86.3881 47.7697 86.4483 47.1771 86.3301C46.5846 86.2119 46.0405 85.9206 45.6136 85.493C45.1866 85.0655 44.8961 84.5209 44.7788 83.9282C44.6615 83.3354 44.7226 82.7213 44.9545 82.1633C45.1864 81.6054 45.5785 81.1287 46.0813 80.7937C46.5841 80.4587 47.175 80.2803 47.7793 80.2813C48.1801 80.2813 48.577 80.3603 48.9473 80.5138C49.3175 80.6673 49.6539 80.8924 49.9371 81.176C50.2203 81.4597 50.4448 81.7964 50.5978 82.1669C50.7508 82.5373 50.8292 82.9344 50.8286 83.3352Z'
        fill='white'
      />
      <path
        d='M98.2114 82.7082C98.2114 83.3122 98.0323 83.9027 97.6967 84.4049C97.3611 84.9071 96.8842 85.2986 96.3261 85.5297C95.7681 85.7609 95.1541 85.8213 94.5617 85.7035C93.9693 85.5857 93.4251 85.2948 92.998 84.8677C92.5709 84.4406 92.28 83.8964 92.1622 83.304C92.0444 82.7116 92.1048 82.0976 92.336 81.5395C92.5671 80.9815 92.9586 80.5045 93.4608 80.169C93.963 79.8334 94.5534 79.6543 95.1575 79.6543C95.9674 79.6543 96.7442 79.976 97.3169 80.5488C97.8896 81.1215 98.2114 81.8983 98.2114 82.7082Z'
        fill='white'
      />
      <path
        d='M92.1212 85.0697C92.1221 85.331 92.0454 85.5867 91.9007 85.8044C91.7561 86.0221 91.5501 86.1919 91.3088 86.2923C91.0676 86.3928 90.8019 86.4193 90.5455 86.3685C90.2892 86.3177 90.0537 86.192 89.8689 86.0072C89.6841 85.8224 89.5583 85.5869 89.5076 85.3305C89.4568 85.0742 89.4833 84.8085 89.5837 84.5672C89.6842 84.326 89.854 84.12 90.0717 83.9753C90.2893 83.8307 90.5451 83.754 90.8064 83.7549C91.1547 83.7561 91.4884 83.895 91.7347 84.1413C91.981 84.3876 92.1199 84.7213 92.1212 85.0697Z'
        fill='white'
      />
      <path
        d='M53.9259 85.0697C53.9268 85.331 53.85 85.5867 53.7054 85.8044C53.5608 86.0221 53.3548 86.1919 53.1135 86.2923C52.8722 86.3928 52.6066 86.4193 52.3502 86.3685C52.0939 86.3177 51.8583 86.192 51.6735 86.0072C51.4887 85.8224 51.363 85.5869 51.3122 85.3305C51.2615 85.0742 51.288 84.8085 51.3884 84.5672C51.4889 84.326 51.6587 84.12 51.8764 83.9753C52.094 83.8307 52.3497 83.754 52.6111 83.7549C52.9594 83.7561 53.2931 83.895 53.5394 84.1413C53.7857 84.3876 53.9246 84.7213 53.9259 85.0697Z'
        fill='white'
      />
      <path
        opacity='0.2'
        d='M40.3945 47.459C40.3945 47.459 61.4215 58.2862 69.1901 67.2957C69.1901 67.2957 61.5045 58.4153 55.6642 54.8862C55.6642 54.8862 66.616 59.1442 74.523 67.2957C74.523 67.2957 83.3203 66.2808 86.5173 63.9281C86.5173 63.9281 80.7923 53.2255 74.523 48.437C74.523 48.4508 58.2199 48.7876 40.3945 47.459Z'
        fill='white'
      />
      <path
        d='M110.163 62.6936C116.575 62.6936 121.773 57.4957 121.773 51.0836C121.773 44.6716 116.575 39.4736 110.163 39.4736C103.751 39.4736 98.5527 44.6716 98.5527 51.0836C98.5527 57.4957 103.751 62.6936 110.163 62.6936Z'
        fill='#FF7E17'
      />
      <path
        d='M105.113 46.0342L115.203 56.1342'
        stroke='white'
        strokeWidth='1.1'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M115.203 46.0342L105.113 56.1342'
        stroke='white'
        strokeWidth='1.1'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default DeviceOfflineIcon;
