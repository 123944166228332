import React, { memo, useEffect, useState } from 'react';
import CloseIcon from '../../../Common/Components/Icons/CloseIcon';
import InfoIcon from '../../../Common/Components/Icons/InfoIcon';
import styles from './message-info.module.scss';

const MessageInfo = memo(() => {
  const [showMessage, setShowMessage] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowMessage(false);
    }, 30000); // 1 min.
  }, []);

  if (!showMessage) {
    return null;
  }

  return (
    <div className={styles.tempBoxWrapper}>
      <div className={styles.tempBoxBlock}>
        <p className={styles.informationMessage}>
          <InfoIcon />
          Real-time data may take a few seconds to load initially.
        </p>
        <button
          className={styles.closeBtn}
          onClick={() => setShowMessage(false)}
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  );
});

export { MessageInfo };
