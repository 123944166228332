import React from 'react';
import { Stations } from '../../Types';
import { extractCameraIds, groupByStationType } from '../../Utils';
import { StationTypeCard } from '../StationCard';
import { StationTypeShifts } from '../StationTypeStats';
import styles from './card-map-view.module.scss';
import useCameraStatusData from '../../Hooks/useGetCameraStatus';
import { useStationShiftsData } from '../../Hooks/useStationShiftsData';
import { getStationTypeIds } from '../StationTypeStats/utils';
import { LeaderboardComponent } from '../LeaderboardComponent';

const CardMapView = ({
  stations,
  location,
  stationDisplayOrder,
  enableLeaderBoardOnOD,
  timezone
}: {
  stations: Stations;
  location: string;
  stationDisplayOrder: string | undefined;
  enableLeaderBoardOnOD: boolean | undefined;
  timezone: string | undefined;
}) => {
  const groupedStations = groupByStationType(stations);
  const { cameraStatusData } = useCameraStatusData(extractCameraIds(stations));
  const {
    activeShiftId,
    loading: shiftLoading,
    stationTypeShiftData
  } = useStationShiftsData({
    location: location,
    stationTypeId: getStationTypeIds(groupedStations)
  });
  const modifiedGroupedStations =
    stationDisplayOrder !== 'default'
      ? [...groupedStations].reverse()
      : groupedStations;
  return (
    <div className={styles.containerWrapper}>
      {' '}
      <div className={styles.topContainer}>
        <div className={styles.container}>
          {modifiedGroupedStations.map((stationsData, index) => {
            return (
              <StationTypeCard
                key={index}
                isPrimary={stationsData[0].primary || false}
                stationType={stationsData[0].type}
                stations={stationsData}
                cameraStatusData={cameraStatusData}
                // cameraStatusData={cameraStatusData}
              />
            );
          })}
        </div>
        <StationTypeShifts
          location={location}
          groupedStations={groupedStations}
          stationDisplayOrder={stationDisplayOrder}
        />
      </div>
      {!shiftLoading && stationTypeShiftData.length ? (
        <LeaderboardComponent
          activeShiftId={activeShiftId}
          location={location}
          enableLeaderBoardOnOD={enableLeaderBoardOnOD}
          timezone={timezone}
        />
      ) : null}
    </div>
  );
};

export default CardMapView;
