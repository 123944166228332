import React, { memo } from 'react';
import GoalMetIcon from '../../../Common/Components/Icons/GoalMetIcon';
import HourglassIcon from '../../../Common/Components/Icons/HourglassIcon';
import { useStationShiftsData } from '../../Hooks/useStationShiftsData';
import { Stations } from '../../Types';
import useGetHidden from './Hooks/useGetHidden';
import useScrollShiftIntoView from './Hooks/useScrollShiftIntoView';
import styles from './StationTypeStats.module.scss';
import TimeTag from './TimeTag';
import {
  getShiftTimeRange,
  getShowMinutes,
  getStationTypeIds,
  showTimeTag
} from './utils';

interface StationTypeShifts {
  location: string;
  groupedStations: Stations[];
  stationDisplayOrder: string | undefined;
}

export const StationTypeShifts: React.FC<StationTypeShifts> = memo(
  ({ location, groupedStations, stationDisplayOrder }: StationTypeShifts) => {
    const { stationTypeShiftData } = useStationShiftsData({
      location: location,
      stationTypeId: getStationTypeIds(groupedStations)
    });

    const reversedStationTypeData =
      stationDisplayOrder !== 'default'
        ? [...stationTypeShiftData].reverse()
        : stationTypeShiftData;
    const isHidden = useGetHidden();
    const showMinutes = getShowMinutes(reversedStationTypeData);
    useScrollShiftIntoView(reversedStationTypeData);

    return stationTypeShiftData &&
      stationTypeShiftData.length &&
      stationTypeShiftData[0].shifts.length ? (
      <div className={styles.container}>
        {reversedStationTypeData.map((stationType) => {
          return (
            <div
              key={stationType.stationTypeId}
              className={`${styles.statsContainer}`}
            >
              <div className={styles.statsHeader}>
                <p>{stationType.stationType} Stats</p>
                <div className={styles.headerIcons}>
                  <HourglassIcon />
                  <GoalMetIcon />
                </div>
              </div>
              <div className={`${styles.statsItemContainer}`}>
                {stationType.shifts.map((shift, index) => {
                  const timeTagVisible = showTimeTag(
                    shift.nextRunAt,
                    stationType.shifts,
                    index
                  );
                  return (
                    <div
                      key={shift._id}
                      className={`${styles.statsItem} ${
                        shift.active && styles.activeItem
                      } activeClass_${shift._id}`}
                      // id={shift.active ? ``:""}
                    >
                      <p
                        className={`${styles.itemTitle} ${styles.flipContainer}  `}
                      >
                        <span className={`${styles.flipper} `}>
                          <span
                            className={`${styles.shiftTitle}
                      ${timeTagVisible && styles.tagVisibleTitle}
                      ${
                        !timeTagVisible &&
                        (isHidden ? styles.flipped : styles.visible)
                      }`}
                          >
                            {shift.shift}
                          </span>
                          {!timeTagVisible && (
                            <span
                              className={`${styles.shiftTitleTime} 
                    ${isHidden ? styles.visible : styles.flipped}`}
                            >
                              {getShiftTimeRange(
                                shift.startTime,
                                shift.endTime,
                                showMinutes
                              )}
                            </span>
                          )}
                        </span>
                      </p>

                      {timeTagVisible ? (
                        <TimeTag
                          shifts={stationType.shifts}
                          shift={shift.nextRunAt}
                          index={index}
                        />
                      ) : (
                        <div className={styles.itemStats}>
                          <p>
                            {shift.averageJourneyTime
                              ? shift.averageJourneyTime
                              : '--:--'}
                          </p>
                          <p>
                            {shift.goal === null ? '----' : shift.goal + '%'}
                          </p>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    ) : null;
  }
);
