import React, { SVGProps } from 'react';

interface FilterLocationIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const FilterLocationIcon = ({
  width = '16',
  height = '16',
  color = 'currentColor',
  ...rest
}: FilterLocationIconProps) => {
  return (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
<g clipPath="url(#clip0_51053_83903)">
<path d="M8 15.4853L3.75734 11.2426C2.91823 10.4035 2.34679 9.3344 2.11529 8.17051C1.88378 7.00663 2.0026 5.80023 2.45673 4.70388C2.91086 3.60753 3.6799 2.67046 4.66659 2.01118C5.65328 1.35189 6.81332 1 8 1C9.18669 1 10.3467 1.35189 11.3334 2.01118C12.3201 2.67046 13.0891 3.60753 13.5433 4.70388C13.9974 5.80023 14.1162 7.00663 13.8847 8.17051C13.6532 9.3344 13.0818 10.4035 12.2427 11.2426L8 15.4853ZM11.3 10.2999C11.9526 9.64728 12.397 8.81576 12.577 7.91053C12.7571 7.0053 12.6646 6.06701 12.3114 5.21432C11.9582 4.36162 11.36 3.63282 10.5926 3.12006C9.82519 2.6073 8.92296 2.33362 8 2.33362C7.07704 2.33362 6.17481 2.6073 5.40739 3.12006C4.63997 3.63282 4.04183 4.36162 3.68861 5.21432C3.33539 6.06701 3.24294 7.0053 3.42297 7.91053C3.603 8.81576 4.04741 9.64728 4.7 10.2999L8 13.5999L11.3 10.2999ZM8 8.33328C7.64638 8.33328 7.30724 8.19281 7.05719 7.94276C6.80715 7.69271 6.66667 7.35357 6.66667 6.99995C6.66667 6.64633 6.80715 6.30719 7.05719 6.05714C7.30724 5.80709 7.64638 5.66661 8 5.66661C8.35362 5.66661 8.69276 5.80709 8.94281 6.05714C9.19286 6.30719 9.33334 6.64633 9.33334 6.99995C9.33334 7.35357 9.19286 7.69271 8.94281 7.94276C8.69276 8.19281 8.35362 8.33328 8 8.33328Z" fill="#CED4DA"/>
</g>
<defs>
<clipPath id="clip0_51053_83903">
<rect width="16" height="16" fill={color}/>
</clipPath>
</defs>
</svg>


  );
};

export default FilterLocationIcon;
