import { request } from '../../Common/Requests/request';
import { getAppConfig } from '../../Config';
import { ILocationFilterResponse } from '../Types/locationFilter';


export const getLocationFilterData = async (): Promise<ILocationFilterResponse> => {
 

  const apiResponse = await request<ILocationFilterResponse>({
    url: `${
      getAppConfig().locationFilterApi || ''
    }`
  });

  return apiResponse;
};
