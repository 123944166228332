import { useEffect } from 'react';
import Events, { EventType } from '../../Common/Utils/events';

export const useAuthentication = (onAuthFailed: CallableFunction) => {
  useEffect(() => {
    const onAuthenticationFailed = () => {
      onAuthFailed();
    };

    Events.registerEvent(
      EventType.AUTHENTICATION_FAILED,
      onAuthenticationFailed
    );

    return () => {
      Events.unregisterEvent(
        EventType.AUTHENTICATION_FAILED,
        onAuthenticationFailed
      );
    };
  }, [onAuthFailed]);
};
