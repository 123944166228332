import { Stations } from '../../../Types';
import { Shifts, shiftData } from '../../../Types/stationShifts';

export const getStationTypeIds = (groupedStations: Stations[]): string => {
  const idArr = groupedStations.map((ele) => ele[0].stationTypeId);

  return idArr.join(',');
};

export const getTimeDifferenceString = (
  futureTimeString: string,
  shifts: Shifts,
  index: number
): string | null => {
  const futureTime = new Date(futureTimeString);
  const currentTime = new Date();
  const timeDifferenceInMilliseconds =
    futureTime.getTime() - currentTime.getTime();
  const timeDifferenceInMinutes = Math.floor(
    timeDifferenceInMilliseconds / (1000 * 60)
  );

  let showTimeTag = false;
  // Determine if the time tag should be shown
  const activeShiftIndex = shifts.findIndex((shift) => shift.active === true);
  if (activeShiftIndex === shifts.length - 1 && index === 0) {
    showTimeTag = true;
  } else if (activeShiftIndex + 1 === index) {
    showTimeTag = true;
  }
  if (showTimeTag && timeDifferenceInMinutes <= 15 && timeDifferenceInMinutes > 0) {
    return `${timeDifferenceInMinutes} mins to go`;
  
  }

  return null;
};

export const showTimeTag = (futureTimeString: string,
  shifts: Shifts,
  index: number):boolean=>{
 if( getTimeDifferenceString(futureTimeString, shifts, index)){
return true
 } 
return false
}


export function getShowMinutes(stationTypeData: shiftData[]): boolean {
  for (const station of stationTypeData) {
    for (const shift of station.shifts) {
      const startDate = new Date(`1970-01-01T${shift.startTime}:00`);
      const endDate = new Date(`1970-01-01T${shift.endTime}:00`);

      if (startDate.getMinutes() !== 0 || endDate.getMinutes() !== 0) {
        return true;
      }
    }
  }

  return false;
}


export function getShiftTimeRange(startTime: string, endTime: string,showMinutes:boolean): string {
  const startDate = new Date(`1970-01-01T${startTime}:00`);
  const endDate = new Date(`1970-01-01T${endTime}:00`);
 
  const startHours = startDate.getHours();
  const startMinutes = startDate.getMinutes();
  const endHours = endDate.getHours();
  const endMinutes = endDate.getMinutes(); 
  const formatTime = (hours: number, minutes: number, showMinutes: boolean): string => {
    const hourString = (hours % 12 || 12).toString();
    const minuteString = showMinutes ? `:${minutes.toString().padStart(2, '0')}` : '';
    const amPm = hours >= 12 ? 'PM' : 'AM';
    return `${hourString}${minuteString} ${amPm}`;
  };
 
  const formattedStartTime = formatTime(startHours, startMinutes, showMinutes);
  const formattedEndTime = formatTime(endHours, endMinutes, showMinutes);
 
  const formattedString = `(${formattedStartTime} - ${formattedEndTime})`;
 
  return formattedString;
}
