import React, { memo } from 'react';
import { useSocketConnection } from '../../Hooks/useSocketConnection';

export const ConnectionDot = memo(
  ({
    connected,
    disconnected
  }: {
    connected: string;
    disconnected: string;
  }) => {
    const { isConnected } = useSocketConnection();

    return <span className={!isConnected ? disconnected : connected} />;
  }
);
