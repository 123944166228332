import { useCallback } from 'react';
import { useMakeRequest } from '../../Common/Hooks/useMakeRequest';
import { calculateAtPremiseVehicleCount } from '../Helpers/locationLayoutStateHelper';
import { getLocationLayoutData } from '../Requests/getLocationLayoutData';
import { ILocationLayoutResponse } from '../Types';

export function useLocationDashboardData({ location }: { location: string }) {
  const fetchLocationLayoutData = useCallback(() => {
    return getLocationLayoutData({ location });
  }, [location]);

  const {
    loading,
    error,
    data: locationLayoutData,
    setData
  } = useMakeRequest<ILocationLayoutResponse>({
    fetcher: fetchLocationLayoutData
  });

  const { stations } = locationLayoutData?.data || {};
  return {
    loading,
    error,
    stations,
    location: locationLayoutData?.data?.location,
    timezone: locationLayoutData?.data?.timezone,
    journeyConfig: {
      runningServices: locationLayoutData?.data?.config?.runningServices,
      journeyEnvironment: locationLayoutData?.data?.config?.environment
    },
    atPremiseVehicleCount: stations
      ? calculateAtPremiseVehicleCount(stations)
      : undefined,
    currentHourCount: locationLayoutData?.data?.currentHourCounts,
    previousHourCount: locationLayoutData?.data?.previousHourCounts,
    stationDisplayOrder: locationLayoutData?.data?.config?.stationDisplayOrder,
    deviceStatus: locationLayoutData?.data?.deviceStatus,
    deviceId: locationLayoutData?.data?.deviceId,
    status: locationLayoutData?.data?.config?.status,
    deploymentType: locationLayoutData?.data?.config?.deploymentType,
    enableLeaderBoardOnOD:
      locationLayoutData?.data?.config?.enableLeaderBoardOnOD,
    updateData: setData
  };
}
