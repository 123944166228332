import { request } from '../../Common/Requests/request';
import { getAppConfig } from '../../Config';
import { ILocationLayoutRequest, ILocationLayoutResponse } from '../Types';

export const getLocationLayoutData = async ({
  location
}: ILocationLayoutRequest): Promise<ILocationLayoutResponse> => {
  if (!location) {
    throw new Error('Location is required');
  }

  const layoutResponse = await request<ILocationLayoutResponse>({
    url: `${getAppConfig().layoutApiUrl || ''}?location=${location}`
  });

  layoutResponse.data.stations = layoutResponse.data.stations.filter(
    ({ halt }) => halt
  );

  return layoutResponse;
};
