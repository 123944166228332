export enum EventType {
  CANCEL_STATION_TIMER = 'CANCEL_STATION_TIMER',
  AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED',
  FETCH_STATS = 'FETCH_STATS'
}

const events: Record<string, Array<CallableFunction | undefined>> = {};

const getEvent = (type: EventType) => {
  return events[type];
};

const isEventsAvailable = (type: EventType) => {
  return getEvent(type);
};

const registerEvent = (type: EventType, fn: CallableFunction) => {
  if (!isEventsAvailable(type)) {
    events[type] = [];
  }

  events[type].push(fn);
};

const unregisterEvent = (type: EventType, fn: CallableFunction) => {
  if (!isEventsAvailable(type)) {
    return;
  }

  events[type] = events[type].filter((event) => event !== fn);
};

const notify = (type: EventType, ...args: Array<any>) => {
  if (!isEventsAvailable(type)) {
    return;
  }

  events[type].forEach((event) => {
    if (typeof event !== 'function') {
      return;
    }
    event(...args);
  });
};

const Events = {
  unregisterEvent,
  registerEvent,
  notify,
  type: EventType
};

export default Events;
