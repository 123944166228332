import * as React from 'react';
import CarIcon from '../../../Common/Components/Icons/CarIcon';
import DefaultStationIcon from '../../../Common/Components/Icons/DefaultStationIcon';
import EntryIcon from '../../../Common/Components/Icons/EntryIcon';
import FastFoodIcon from '../../../Common/Components/Icons/FastFoodIcon';
import PickUpIcon from '../../../Common/Components/Icons/PickUpIcon';
import TimerIcon from '../../../Common/Components/Icons/Timer';
import GoalFlagIcon from '../../../Common/Components/Icons/goalFlag';
import { calculateTimeDiff } from '../../../Common/Utils/dateTime';
import {
  AccessPoint,
  ICameraStatusResponse,
  StationType,
  Stations,
  vehiclesData
} from '../../Types';
import ProgressBar from '../ProgressBar';
import styles from './station-card.module.scss';
import WarningIcon from '../../../Common/Components/Icons/WarningIcon';
import { convertSeconds, getOfflineStationCameras } from './utils/index';

const StationIcon = ({ type }: { type?: StationType }) => {
  switch (type) {
    case StationType.WINDOW:
    case StationType.PICKUP:
      return <PickUpIcon />;
    case StationType.MENU:
      return (
        <FastFoodIcon
          style={{
            transform: 'rotate(90deg)'
          }}
        />
      );
    case StationType.ENTRY:
      return <EntryIcon style={{ transform: 'rotate(90deg)' }} />;
    default:
      return <DefaultStationIcon style={{ transform: 'rotate(90deg)' }} />;
  }
};

const StationDetails = ({
  isSingleStation,
  stationId,
  stationCode,
  isPrimary,
  goalFormatted,
  goal,
  vehicles,
  crossCameraStation,
  cameraStatusData,
  exit,
  entry
}: {
  isSingleStation: boolean;
  stationId: string;
  station: string;
  isPrimary: boolean;
  stationCode: string;
  goalFormatted: string;
  goal: number;
  vehicles: Array<vehiclesData>;
  crossCameraStation: boolean;
  cameraStatusData: ICameraStatusResponse['data'];
  entry: AccessPoint;
  exit: AccessPoint;
}) => {
  const offlineStationCameras = getOfflineStationCameras(
    cameraStatusData,
    entry?.camera?._id,
    exit?.camera?._id
  );

  return (
    <div
      id={stationId}
      className={
        isSingleStation
          ? styles.singleStationCardWrapper
          : styles.stationCardWrapper
      }
    >
      <div className={isPrimary ? styles.largeStationCard : styles.stationCard}>
        <p className={styles.stationName}>{stationCode}</p>

        {offlineStationCameras.length ? (
          <div className={styles.cameraOfflineContainer}>
            <div className={styles.cameraOfflineWrapper}>
              <WarningIcon />
              <p className={styles.cameraOfflineHeading}>Camera is offline </p>
              <p className={styles.cameraOfflineInfo}>
                Last seen online{' '}
                {convertSeconds(offlineStationCameras[0].lastActive)} ago
              </p>
            </div>
          </div>
        ) : (
          <div className={styles.progress}>
            <p className={styles.topSpacing} />
            {/** this is used as empty space with height */}
            <ProgressBar
              isCircular
              isPrimary={isPrimary}
              stationId={stationId}
              currentVehicleTime={
                vehicles && vehicles.length
                  ? vehicles[0]?.currentVehicleTime
                  : undefined
              }
              total={goal}
              numberOfVehicles={vehicles.length}
              crossCameraStation={crossCameraStation}
            />
          </div>
        )}
        <p className={styles.goalDetails}>
          <GoalFlagIcon className={styles.flag} />
          <span>{goalFormatted}</span>
        </p>
      </div>
    </div>
  );
};

const ConsecutiveTimer = ({
  time,
  queueLength
}: {
  time: number;
  queueLength: number;
}) => {
  const [{ current = '--:--' }, setCurrentTime] = React.useState(
    calculateTimeDiff(time)
  );

  React.useEffect(() => {
    // if (typeof currentVehicleTime !== 'number') {
    //   setCurrentTime({});
    //   return;
    // }

    const interval = setInterval(() => {
      setCurrentTime(calculateTimeDiff(time));
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [time]);

  return (
    <div className={`${styles.consecutiveTimer} `}>
      {queueLength > 1 ? (
        <React.Fragment>
          <TimerIcon />
          <p className={styles.consecutiveTimerTxt}>{current}</p>
          <CarIcon
            color='#A0A0A0'
            style={{
              transform: 'rotate(90deg)',
              marginLeft: '4px'
            }}
          />
        </React.Fragment>
      ) : (
        <div className={styles.timerPlaceholder}>Icon</div>
      )}
    </div>
  );
};

export const StationTypeCard = ({
  isPrimary,
  stationType,
  stations: originalStations,
  cameraStatusData
}: {
  isPrimary: boolean;
  stationType: string;
  stations: Stations;
  cameraStatusData: ICameraStatusResponse['data'];
}) => {
  const stations = originalStations;
  const renderCars = (
    entry: AccessPoint,
    exit: AccessPoint,
    inQueueVehicleCount?: number
  ) => {
    const isOffline = getOfflineStationCameras(
      cameraStatusData,
      entry?.camera?._id,
      exit?.camera?._id
    ).length;

    if (isOffline || !inQueueVehicleCount || inQueueVehicleCount < 1) {
      return null;
    }
    const hasVehiclesInQueue = inQueueVehicleCount > 1;

    return (
      <React.Fragment>
        {inQueueVehicleCount ? (
          <span className={styles.vehicle}>
            <CarIcon
              color='#A0A0A0'
              style={{
                transform: 'rotate(90deg)',
                marginRight: '20px'
              }}
            />
            {hasVehiclesInQueue && (
              <span className={styles.highlightingCircle} />
            )}
          </span>
        ) : null}

        {/* {hasVehiclesInQueue ? (
          <p
            className={`${styles.vehicleCount} ${isPrimary? styles.additionalVehicleCount:""}`}
          >
            <span style={{ margin: '0 auto' }}>+{inQueueVehicleCount - 1}</span>
          </p>
        ) : null} */}
      </React.Fragment>
    );
  };
  return (
    <div className={styles.stationTypeContainer}>
      <div className={styles.stationTypeName}>
        <div className={styles.stationNameWrapper}>
          <p className={styles.stationName}>
            <StationIcon type={stations[0]?.type} />
            <span>{stationType} Stations</span>
          </p>
        </div>
      </div>
      <div
        className={`${styles.details} ${
          isPrimary ? styles.primaryBackground : ''
        }`}
      >
        {stations.map(({ stationId, station, vehicles, entry, exit }) => (
          <div key={stationId} className={styles.station}>
            <p className={styles.name}>{station}</p>
            <div
              className={`${styles.stationRoad} ${
                !isPrimary ? styles.stationRoadPrimary : ''
              }`}
            >
              <span className={styles.dashedDivider} />
              {renderCars(entry, exit, vehicles.length)}
            </div>
            <ConsecutiveTimer
              time={vehicles.length > 1 ? vehicles[1].currentVehicleTime : 0}
              queueLength={vehicles.length || 0}
            />
          </div>
        ))}
      </div>
      <div
        className={`${styles.stationDetails} ${
          isPrimary ? styles.primaryBackground : ''
        }`}
      >
        {stations.map(
          (
            {
              stationId,
              station,
              goal,
              goalFormatted,
              label,
              vehicles,
              crossCameraStation,
              entry,
              exit
            },
            index
          ) => (
            <StationDetails
              isSingleStation={stations.length === 1}
              key={stationId}
              stationId={stationId}
              station={station}
              isPrimary={isPrimary}
              stationCode={label || `${index + 1}`}
              goalFormatted={goalFormatted}
              goal={goal}
              vehicles={vehicles}
              crossCameraStation={crossCameraStation}
              cameraStatusData={cameraStatusData}
              entry={entry}
              exit={exit}
            />
          )
        )}
      </div>
    </div>
  );
};
