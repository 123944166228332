import { useCallback } from 'react';
import { useMakeRequest } from '../../Common/Hooks/useMakeRequest';
import { getLocationFilterData } from '../Requests/getLocationFilterData';
import { ILocationFilterResponse } from '../Types/locationFilter';
import { getAppConfig } from '../../Config';

export function useLocationFilterData() {
  const fetchLocationFilterData = useCallback(() => {
    return getLocationFilterData();
  }, [location]);

  const {
    loading:filterLoading,
    error,
    data,
    setData
  } = useMakeRequest<ILocationFilterResponse>({
    fetcher: fetchLocationFilterData
  });
 
  const THRUPUT_LIVE="thruputLive";
  const JOURNEY="thruputJourney";
  const REVIEW="Review"
  const showJourneyDataAuth = getAppConfig().showJourneyDataAuth;

  const OdLocations=data?.data?.filter((location)=>{
    if (location.runningServices.includes(THRUPUT_LIVE) && location.status==="Active") {
      if (location.liveDataEnvironment === REVIEW) {
        if (showJourneyDataAuth) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  });

  const locationFilterData = OdLocations?.map(locationData => {
    const { _id, area } = locationData.location;
    const commonReturn = { value: _id, label: area };
  
    if (locationData.runningServices.includes(JOURNEY)) {
      if (locationData.environment === REVIEW) {
        return showJourneyDataAuth 
          ? { ...commonReturn, listPage: true } 
          : commonReturn;
      }
      return commonReturn;
    }
  
    return { ...commonReturn, listPage: true };
  });
  
  return {
    filterLoading,
    error,
    locationFilterData,
    updateData: setData
  };
}
