import React, { SVGProps } from 'react';

interface FastFoodIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const FastFoodIcon = ({
  height = '24',
  width = '24',
  color = 'currentColor',
  ...rest
}: FastFoodIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g clipPath='url(#clip0_43613_300027)'>
        <path
          d='M21.98 1C22.54 1 22.99 1.45 22.99 2.01L22.99 15C22.99 15.56 22.54 16.01 21.98 16.01L21 16.01L21 1L21.98 1ZM8.99 8.5C8.99 4.75 11 1 15 1L15 16C11 16 8.99 12.25 8.99 8.5ZM13 3.62C11.45 4.73 10.99 7.09 10.99 8.5C10.99 9.91 11.45 12.27 13 13.38L13 3.62ZM17 1L17 16L19 16L19 1L17 1ZM5 18L1 18L1 16L5 16L5 11L7 11.23L7 20.79L21 19.39L21 18L23 18L23 19.72C23 20.56 22.35 21.25 21.53 21.35L5 23L5 18Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_43613_300027'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='matrix(-1.19249e-08 1 1 1.19249e-08 0 0)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FastFoodIcon;
