import { useEffect } from 'react';
import { shiftData } from '../../../Types/stationShifts';
const useScrollShiftIntoView = (data: shiftData[]) => {
  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }

    const addScroll = async () => {
      for (const station of data) {
        if (station.shifts.length) {
          const activeIndex = station.shifts.findIndex(item => item.active);
          const shouldScroll = (station.shifts.length - activeIndex) <= 4 && activeIndex !== -1;
          for (const ele of station.shifts) {

            if (ele.active && shouldScroll) {
              const selectedElements = document?.getElementsByClassName(
                `activeClass_${ele._id}`
              );

              if (selectedElements.length > 0) {
                const elementsArray = Array.from(selectedElements);

                for (const selectedElement of elementsArray) {
                  selectedElement.scrollIntoView({
                    behavior: 'smooth',
                  });
                  await new Promise((resolve) => setTimeout(resolve, 300));
                }
              }
            }
          }
        }
      }
    };

    addScroll();
  }, [data]);
};

export default useScrollShiftIntoView;
