import { useCallback, useEffect } from 'react';
import { useMakeRequest } from '../../Common/Hooks/useMakeRequest';
import Events from '../../Common/Utils/events';
import { getStationShiftsData } from '../Requests/getStationShiftsData';
import { IStationShiftsResponse, shiftData } from '../Types/stationShifts';

export function useStationShiftsData({
  location,
  stationTypeId
}: {
  location: string;
  stationTypeId: string;
}) {
  const fetchStationShiftsData = useCallback(() => {
    return getStationShiftsData({ location, stationTypeId });
  }, [location]);

  const {
    loading,
    error,
    data: stationShiftsData,
    refreshData
  } = useMakeRequest<IStationShiftsResponse>({
    fetcher: fetchStationShiftsData
  });

  useEffect(() => {
    Events.registerEvent(Events.type.FETCH_STATS, refreshData);

    return () => {
      Events.unregisterEvent(Events.type.FETCH_STATS, refreshData);
    };
  }, [refreshData]);
  function getActiveShift(shiftData: shiftData[]) {
    let activeShift = '';

    if (shiftData.length) {
      activeShift =
        shiftData[0].shifts.find((shift) => shift.active)?._id || '';
    }

    return activeShift;
  }

  const stationTypeShiftData = stationShiftsData?.data || [];
  const activeShiftId = getActiveShift(stationTypeShiftData) || '';
  return {
    loading,
    error,
    stationTypeShiftData,
    activeShiftId
  };
}
