import React, { CSSProperties } from 'react';
import styles from './loader.module.scss';

export const Loader = ({
  style,
  loaderStyle
}: {
  style?: CSSProperties;
  loaderStyle?: CSSProperties;
}) => {
  return (
    <div className={styles.container} style={style}>
      <div className={styles.loader} style={loaderStyle} />
    </div>
  );
};
