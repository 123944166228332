import React, { SVGProps } from 'react';

interface DefaultStationIcon extends SVGProps<SVGSVGElement> {
  color?: string;
}

const DefaultStationIcon = ({
  width = '24',
  height = '24',
  color = 'currentColor',
  ...rest
}: DefaultStationIcon) => {
  return (
    <svg  width={width}
    height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M13 3H20C20.5523 3 21 3.4477 21 4V20C21 20.5523 20.5523 21 20 21H13V22H11L6 21L6 3L11 2H13V3ZM13 19H19V5H13L13 19ZM11 19.9604L11 4.0396L8 4.6396V19.3604L11 19.9604ZM14 18V10H17V18H14ZM3 21L3 3H5L5 21H3Z" fill={color}/>
    </svg>
    
  );
};

export default DefaultStationIcon;
