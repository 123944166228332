import React, { SVGProps } from 'react';

interface DayIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const DayIcon = ({
  height = '20',
  width = '20',
  color = 'currentColor',
  ...rest
}: DayIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M9.99992 14.9997C7.23849 14.9997 4.99992 12.7611 4.99992 9.99967C4.99992 7.23825 7.23849 4.99967 9.99992 4.99967C12.7613 4.99967 14.9999 7.23825 14.9999 9.99967C14.9999 12.7611 12.7613 14.9997 9.99992 14.9997ZM9.99992 13.333C11.8408 13.333 13.3333 11.8406 13.3333 9.99967C13.3333 8.15872 11.8408 6.66634 9.99992 6.66634C8.15897 6.66634 6.66659 8.15872 6.66659 9.99967C6.66659 11.8406 8.15897 13.333 9.99992 13.333ZM9.16659 0.833008H10.8333V3.33301H9.16659V0.833008ZM9.16659 16.6663H10.8333V19.1663H9.16659V16.6663ZM2.92885 4.10712L4.10736 2.92861L5.87513 4.69637L4.69662 5.87488L2.92885 4.10712ZM14.1247 15.303L15.3033 14.1244L17.071 15.8923L15.8925 17.0708L14.1247 15.303ZM15.8925 2.92861L17.071 4.10712L15.3033 5.87488L14.1247 4.69637L15.8925 2.92861ZM4.69662 14.1244L5.87513 15.303L4.10736 17.0708L2.92885 15.8923L4.69662 14.1244ZM19.1666 9.16634V10.833H16.6666V9.16634H19.1666ZM3.33325 9.16634V10.833H0.833252V9.16634H3.33325Z'
        fill={color}
      />
    </svg>
  );
};

export default DayIcon;
