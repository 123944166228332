import React, { SVGProps } from 'react';

interface LeftArrowIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const LeftArrowIcon: SvgrComponent = ({
  height = '10',
  width = '6',
  color = 'currentColor',
  ...rest
}: LeftArrowIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox='0 0 6 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.5575 1.5575L4.5 0.5L0 5L4.5 9.5L5.5575 8.4425L2.1225 5L5.5575 1.5575Z'
        fill={color}
      />
    </svg>
  );
};

export default LeftArrowIcon;
