import React, { SVGProps } from 'react';

interface CarIconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const CarIcon = ({
  height = '40',
  width = '25',
  color = 'currentColor',
  ...rest
}: CarIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 45 65'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g clipPath='url(#clip0_41791_258614)'>
        <rect x='8' y='19' width='29' height='35' fill='black' />
        <path d='M10 6.5L16 3L16.5 5L11 10.5H8.5L10 6.5Z' fill='black' />
        <path d='M32.5 9.5L29 3.5L31 3L36.5 8.5V11L32.5 9.5Z' fill='black' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.83467 14.6372C7.14086 17.6008 7.17672 20.9148 7.10086 24.4402C5.20308 24.4464 3.77147 24.6172 2.5564 25.2626C1.87231 25.6264 1.40063 26.0345 1.11927 26.7561C0.930319 27.2846 1.06962 27.6669 1.43786 27.7984C2.06126 28.0234 4.55899 27.7653 5.35755 27.6091C5.69821 27.5428 5.98233 27.4518 6.10784 27.3227C6.18369 27.2453 6.24576 27.1506 6.29403 27.0388C6.49953 26.5557 6.32575 26.0013 6.73813 25.5539C6.83881 25.4457 6.95191 25.3855 7.07879 25.356C6.87743 33.003 6.22231 41.5694 6.68158 49.6798C6.68158 55.0158 6.8195 58.4404 12.6783 61.72C18.3965 64.9209 28.9433 64.8201 34.0904 60.6862C38.5646 57.0932 38.2804 54.4516 38.2804 49.6798C39.0307 41.031 38.4473 33.1419 38.1715 25.3793C38.2666 25.4138 38.3535 25.4691 38.4321 25.5539C38.8445 26.0013 38.6707 26.5557 38.8762 27.0388C38.9245 27.1506 38.9866 27.2453 39.0624 27.3227C39.1879 27.4518 39.4721 27.5428 39.8127 27.6091C40.6113 27.7653 43.109 28.0234 43.7324 27.7984C44.1007 27.6669 44.24 27.2846 44.051 26.7561C43.7697 26.0345 43.298 25.6264 42.6139 25.2626C41.414 24.6258 40.0031 24.4513 38.1398 24.4402C38.0363 21.2455 37.9991 18.0692 38.1384 14.8658C39.0004 -4.93937 5.1024 -2.12814 6.83467 14.6372ZM16.438 3.46603C12.5197 4.77147 9.65514 6.98283 8.49799 10.5377C11.4012 8.59434 14.9982 5.65405 16.438 3.46603ZM28.7322 2.95222C32.6505 4.25765 35.5165 6.46902 36.6723 10.0239C33.7691 8.08053 30.1721 5.14023 28.7322 2.95222ZM10.833 48.721L11.997 45.7537C19.4157 46.7813 27.1324 46.7026 33.2864 45.7352L34.3359 48.5378C26.0359 52.0964 19.5275 52.4836 10.833 48.721ZM36.384 44.5257C37.1922 40.334 37.0461 33.8831 36.1096 29.7C34.3663 35.5548 34.7635 38.859 36.384 44.5257ZM10.6799 30.3577L9.25379 24.5558C15.5112 17.4902 29.7184 17.9782 35.9165 24.5558L34.1332 30.3577C26.1917 28.5913 18.3841 28.7339 10.6799 30.3577ZM8.78624 44.5257C7.97803 40.334 8.12423 33.8831 9.0607 29.7C10.8026 35.5548 10.4068 38.859 8.78624 44.5257Z'
          fill={color}
          stroke={color}
          strokeWidth='0.5'
          strokeMiterlimit='22.926'
        />
      </g>
      <defs>
        <clipPath id='clip0_41791_258614'>
          <rect width='45' height='65' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CarIcon;
