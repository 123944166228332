export interface AppConfig {
  baseApiUrl: string;
  layoutApiUrl: string;
  shiftApiUrl: string;
  locationFilterApi: string;
  cameraStatusApi: string;
  leaderboardApi: string;
  enableSocket: boolean;
  socketServerUrl?: string;
  authToken?: string;
  userId?: string;
  companyId?: string;
  deviceId: string;
  device: string;
  deviceType: string;
  timezone: string;
  isExternalDashboard?: boolean;
  showJourneyDataAuth?: boolean;
}

let appConfig: AppConfig;

export const getAppConfig = () => {
  if (!appConfig) {
    throw new Error(
      `'initializeConfig' before using the operational dashboard component`
    );
  }
  return appConfig;
};

const setConfig = (config: AppConfig) => {
  appConfig = config;
};

export const initializeConfig = (config: AppConfig) => {
  if (!config.baseApiUrl) {
    throw new Error(`'config.baseApiUrl' is required`);
  }

  setConfig(config);
};
