import React, { memo } from 'react';
import Header from './Components/Header';
import { ThemeContext } from './Constants/themeContext';
import { useCompanyLocationRoomSocket } from './Hooks/useCompanyLocationRoomSocket';
import { useCompanyLocationSocket } from './Hooks/useCompanyLocationSocket';
import { useDashboardTheme } from './Hooks/useDashboardTheme';
import { useLocationDashboardData } from './Hooks/useLocationDashboardData';
import styles from './operational-dashboard.module.scss';

// import RoadMapView from './Components/RoadMapView';
// const RoadMapView = lazy(() => import('./Components/RoadMapView'));
import CardMapView from './Components/CardMapView';
import { SocketConnectionInfoBar } from './Components/SocketConnectionInfoBar';
import useGetLocationFilter from './Hooks/UseGetLocationFilter';
import { useAuthentication } from './Hooks/useAuthentication';
import { useLocationFilterData } from './Hooks/useLocationFilterData';
import { DEVICE_ONLINE, EDGE, TASK_ACTIVE } from './Utils';
import DashboardUnavailable from './Components/DashboardUnavailable';
import Logger from '../Common/Utils/logger';
import { getAppConfig } from '../Config';

export interface IOperationalDashboardProps {
  locationId: string;
  locationName: string;
  companyLogoUrl: string;
  goBack: (ulrLocationId: string, fromList?: boolean) => void;
  onAuthFailed: () => void;
}

const OperationalDashboard = memo(
  ({
    locationId,
    locationName,
    companyLogoUrl,
    goBack,
    onAuthFailed
  }: IOperationalDashboardProps) => {
    useAuthentication(onAuthFailed);
    const { theme, toggleTheme } = useDashboardTheme();
    /**
     * calls api and manages the stations state
     *
     */
    const { locationFilterData, filterLoading } = useLocationFilterData();
    const { filterlocation, setFilterlocation } = useGetLocationFilter(
      locationId,
      locationFilterData
    );

    const selectedLocationId = filterlocation?.value || '';
    const {
      loading,
      error,
      stations,
      timezone,
      updateData,
      atPremiseVehicleCount,
      stationDisplayOrder,
      previousHourCount = '-',
      currentHourCount = '-',
      journeyConfig,
      deviceStatus,
      deviceId,
      deploymentType,
      status,
      enableLeaderBoardOnOD
    } = useLocationDashboardData({
      location: selectedLocationId || ''
    });
    const edgeDeployed = deploymentType === EDGE;
    /**
     * Updates the location state based on received socket events
     */
    useCompanyLocationSocket({ updateDataDispatch: updateData });
    useCompanyLocationRoomSocket({ selectedLocationId });
    const queryParams = new URLSearchParams(window.location.search);
    const fromList = !!queryParams.get('fromList');
    const deviceOnline = deviceStatus === DEVICE_ONLINE;
    const hasDeviceId = !!deviceId;
    const isExternalDashboard = getAppConfig().isExternalDashboard;
    const renderContent = () => {
      const taskPaused = status !== TASK_ACTIVE;

      if (loading || filterLoading) {
        return <p className={styles.info}>loading</p>;
      }
      Logger(edgeDeployed && !hasDeviceId);
      if (
        (isExternalDashboard && taskPaused) ||
        (isExternalDashboard && error) ||
        !Array.isArray(stations) ||
        !stations.length
      ) {
        return <DashboardUnavailable />;
      }

      return (
        <CardMapView
          stations={stations}
          location={selectedLocationId}
          stationDisplayOrder={stationDisplayOrder}
          enableLeaderBoardOnOD={enableLeaderBoardOnOD}
          timezone={timezone}
        />
      );

      // return (
      //   <RoadMapView
      //     stations={stations}
      //     previousHourCount={previousHourCount}
      //     currentHourCount={currentHourCount}
      //   />
      // );
    };

    return (
      <ThemeContext.Provider value={theme}>
        <div className={`${styles.sectionWrapper} wobot-od-${theme}`}>
          <Header
            locationName={locationName}
            companyLogoUrl={companyLogoUrl}
            timezone={timezone}
            goBack={goBack}
            theme={theme}
            toggleTheme={toggleTheme}
            atPremiseVehicleCount={atPremiseVehicleCount}
            previousHourCount={previousHourCount}
            currentHourCount={currentHourCount}
            locationFilterData={locationFilterData}
            setFilterlocation={setFilterlocation}
            filterlocation={filterlocation}
            journeyConfig={journeyConfig}
            fromList={fromList}
          />
          <section className={styles.container}>{renderContent()}</section>
          <SocketConnectionInfoBar
            deviceOnline={deviceOnline}
            edgeDeployed={edgeDeployed}
            hasDeviceId={hasDeviceId}
          />
        </div>
      </ThemeContext.Provider>
    );
  }
);

export default OperationalDashboard;
