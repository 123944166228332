import React, { Fragment, memo, useEffect, useState } from 'react';
import Select from 'react-select';
import FilterLocationIcon from '../../../Common/Components/Icons/FilterLocationIcon';
import LeftArrowIcon from '../../../Common/Components/Icons/LeftArrowIcon';
import TimerIcon from '../../../Common/Components/Icons/Timer';
import DayIcon from '../../../Common/Components/Icons/day';
import NightIcon from '../../../Common/Components/Icons/night';
import { colourStyles } from '../../../Common/Utils/colourStyles';
import { formatDateAndTime, now } from '../../../Common/Utils/dateTime';
import { getAppConfig } from '../../../Config';
import { IFilter, JourneyConfig } from '../../Types';
import { Theme } from '../../Types/theme';
import { ConnectionDot } from '../ConnectionDot';
import styles from './header.module.scss';
import { getShowJourneyData, updateUrl } from './Utils';

export interface IHeaderProps {
  locationName: string;
  companyLogoUrl: string;
  timezone?: string;
  goBack: (ulrLocationId: string, fromList?: boolean) => void;
  toggleTheme: () => void;
  theme: Theme;
  atPremiseVehicleCount?: number | string;
  previousHourCount?: number | string;
  currentHourCount?: number | string;
  locationFilterData?: IFilter[];
  setFilterlocation: CallableFunction;
  filterlocation: { value: string; label: string };
  journeyConfig: JourneyConfig | undefined;
  fromList?: boolean;
}

const Header = memo(
  ({
    theme,
    companyLogoUrl,
    timezone,
    goBack,
    toggleTheme,
    // atPremiseVehicleCount = '-',
    currentHourCount = '-',
    previousHourCount = '-',
    locationFilterData,
    setFilterlocation,
    filterlocation,
    journeyConfig,
    fromList
  }: IHeaderProps) => {
    const [{ date, time }, setDateAndTime] = useState<{
      date: string;
      time: string;
    }>(formatDateAndTime(now(), timezone));
    const { showJourneyData } = getShowJourneyData(journeyConfig);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const getFilterLabel = () => {
      const resultLocation = locationFilterData?.find(
        (location) => location.value === filterlocation?.value
      );
      return {
        ...resultLocation,
        label: (
          <span className={styles.filterLabel}>
            <FilterLocationIcon />{' '}
            <span className={styles.filterLabelText}>
              {resultLocation?.label}
            </span>
          </span>
        )
      };
    };

    useEffect(() => {
      setTimeout(() => {
        setDateAndTime(formatDateAndTime(now(), timezone));
      }, 1000);
    }, [date, time, timezone]);
    return (
      <header className={styles.header}>
        <div className={styles.leftContent}>
          {companyLogoUrl && (
            <img className={styles.logo} src={companyLogoUrl} alt='logo' />
          )}

          <button
            className={styles.backButton}
            onClick={() => {
              goBack(filterlocation.value, fromList);
            }}
          >
            <LeftArrowIcon />
          </button>
          <span>ThruPut Live</span>
          <ConnectionDot
            connected={styles.connectedDot}
            disconnected={styles.disconnectedDot}
          />
        </div>
        <div className={styles.centerContent}>
          {showJourneyData && (
            <ul className={styles.statsContainer}>
              {[
                // { label: 'At Premise', value: atPremiseVehicleCount },
                { label: 'Served in current hour', value: currentHourCount },
                { label: 'Previous hour', value: previousHourCount }
              ].map(({ label, value }) => (
                <li key={label} className={styles.stats}>
                  <span className={styles.label}>{label}:</span>
                  <span className={styles.value}>{value}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
        {getAppConfig().isExternalDashboard ? (
          <div className={styles.filterContent}>
            <Select
              styles={colourStyles}
              className={`${styles.filterSelect} ${
                isMenuOpen && styles.menuOpen
              }`}
              isSearchable={false}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null
              }}
              // @ts-ignore
              options={locationFilterData}
              onChange={(value) => {
                setFilterlocation(value);
                // @ts-ignore
                // @ts-ignore
                if (value.listPage) {
                  updateUrl('fromList', 'true');
                } else {
                  updateUrl('fromList', 'false');
                }
              }}
              value={getFilterLabel()}
              onMenuOpen={() => setIsMenuOpen(true)}
              onMenuClose={() => setIsMenuOpen(false)}
            />
          </div>
        ) : null}
        <div className={styles.rightContent}>
          {/* <div className={`${styles.filterSection} ${styles.location}`}>
            <label className={styles.subtitle}>
              <span>
                <LocationIcon />
              </span>
              {locationName}
            </label>
          </div> */}
          <div className={`${styles.filterSection} ${styles.datetime}`}>
            <label className={styles.subtitle}>
              {timezone ? (
                <Fragment>
                  <span>
                    <TimerIcon style={{ opacity: 0.8 }} />
                  </span>
                  <span
                    className={`${styles.dateTimeLabel}`}
                  >{`${date}, ${time}`}</span>
                </Fragment>
              ) : null}
            </label>
            <button
              className={`${styles.themeButton} ${theme}`}
              onClick={toggleTheme}
            >
              <span
                className={`${styles.dark} ${
                  theme === Theme.dark ? styles.active : ''
                }`}
              >
                <NightIcon />
              </span>
              <span
                className={`${styles.light} ${
                  theme === Theme.light ? styles.active : ''
                }`}
              >
                <DayIcon />
              </span>
            </button>
          </div>
        </div>
      </header>
    );
  }
);

export default Header;
