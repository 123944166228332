import { ManagerOptions, Socket, SocketOptions, io } from 'socket.io-client';
import { getAppConfig } from '../../Config';
import Logger from './logger';

let socket: Socket;

const getSocketServerConfig = (): {
  enableSocket: boolean;
  socketServerUrl: string | undefined;
  authToken?: string;
  userId?: string;
  deviceId: string;
} => {
  const { enableSocket, socketServerUrl, authToken, userId, deviceId } =
    getAppConfig();
  return { enableSocket, socketServerUrl, authToken, userId, deviceId };
};

export const getClientSocket = () => {
  const { enableSocket, socketServerUrl, authToken, userId, deviceId } =
    getSocketServerConfig();

  if (!enableSocket || !userId) {
    return;
  }

  if (socket) {
    return socket;
  }

  const options: Partial<ManagerOptions & SocketOptions> = {
    path: '/socket',
    auth: {
      token: authToken,
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      deviceId,
      deviceType: 'browser'
    },
    query: {
      userId,
      deviceUserType: 'web',
      transports: ['websocket', 'polling']
    }
  };

  Logger('socket options', options);

  if (socketServerUrl) {
    socket = io(socketServerUrl, options);
  } else {
    socket = io(options);
  }

  return socket;
};
